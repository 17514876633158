import { useEffect, useState } from "react";

export function useScript(
    url: string,
    onLoad: () => void,
    integrity?: string,
    async = true,
    crossOrigin = "anonymous",
) {
    useEffect(() => {
        const script = document.createElement("script");

        script.src = url;

        script.async = async;

        if (integrity) {
            script.integrity = integrity;
        }

        // script.crossOrigin = crossOrigin
        script.onload = onLoad;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);
}

export function buildUrl(url: string, searchParams: { [key: string]: string | undefined }): string {
    let processedUrl = url;

    Object.keys(searchParams).forEach((key) => {
        const value = searchParams[key];
        if (value) {
            if (processedUrl.indexOf("?") === -1) {
                processedUrl += "?";
            } else {
                processedUrl += "&";
            }

            processedUrl += encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }
    });

    return processedUrl;
}

export function useJsonData<T>(url: string): T | null {
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((json) => setData(json as T));
    }, [url]);

    return data;
}

export function useCharts(): boolean {
    const [loaded, setLoaded] = useState(false);

    useScript("https://www.gstatic.com/charts/loader.js", () => {
        google.charts.load("current", { packages: ["corechart", "gauge"] }).then(() => {
            setLoaded(true);
        });
    });

    return loaded;
}
