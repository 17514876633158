import {AppVersionName, Filters, ISOTimestamp} from "../StatisticsProcessingPage";
import {DevicePlatform} from "../../../generated/gql/graphql";

export type RowFilterable = [
    ticketCreatedTime: ISOTimestamp,
    platform: DevicePlatform | null,
    appVersion: AppVersionName | null
];

export function filterData<R extends [...RowFilterable, ...any[]]>(
    filters: Filters,
    data: ReadonlyArray<R>
): ReadonlyArray<R> {
    return data.filter((row) => {
        if (filters.appVersion) {
            const versionName: AppVersionName | null = row[2];
            if (versionName !== filters.appVersion)
                return false
        }

        if (filters.platform) {
            const platform: DevicePlatform | null = row[1];
            if (platform !== filters.platform)
                return false
        }

        return true;
    });
}
