import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: white;

    @media print {
        overflow: visible;
        position: relative;
        height: auto;
        width: auto;
    }
`;

export default React.memo<React.HTMLAttributes<HTMLDivElement>>(({ ...params }) => {
    return <StyledDiv {...params} />;
});
