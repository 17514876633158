import React, { memo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import {
    TicketComplaintFragment,
    TicketComplaintsQuery,
    TicketComplaintsQueryVariables,
    TicketComplaintStatus,
} from "../generated/gql/graphql";
import { Link, useLocation } from "react-router-dom";
import {
    Chip,
    ChipProps,
    CircularProgress,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { usePageTitle } from "../util/page_title";
import { formatCustomerId, formatTicketId } from "../util/formatting";
import { TimestampText } from "../widgets/date_time";
import { buildFlagIcon } from "./FrontPage";
import CallIcon from "@mui/icons-material/Call";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { formatMoney, formatMoneyShort } from "./NetsTransactionsListPage";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { JsonDiagnosticButton } from "../widgets/diagnostics";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const TICKET_COMPLAINT_FRAGMENT = gql`
    fragment TicketComplaint on TicketComplaint {
        id
        customerEmail
        status
        statusChangedByEmail
        statusChangedTime
        description
        createdTime
        originalPrice
        correctPrice
        customerMessage

        ticket {
            id
            customer {
                id
                name
                phoneNumberFormatted
            }
            hasTelemetryData
        }
    }
`;

const TICKET_COMPLAINTS_QUERY = gql`
    query TicketComplaints($page: Int!, $limit: Int!) {
        ticketComplaints(page: $page, limit: $limit) {
            totalPages
            result {
                ...TicketComplaint
            }
        }
    }
    ${TICKET_COMPLAINT_FRAGMENT}
`;

export const TicketComplaintListPage = memo(() => {
    usePageTitle("Klager");
    const basePath = "/complaints";

    const location = useLocation();
    const locationQuery = new URLSearchParams(location.search);
    const page = parseInt(locationQuery.get("page") || "0", 10) || 1;
    const query = useQuery<TicketComplaintsQuery, TicketComplaintsQueryVariables>(TICKET_COMPLAINTS_QUERY, {
        variables: {
            page: page - 1,
            limit: 40,
        },
    });
    const transactions = (query.data || query.previousData)?.ticketComplaints;

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <div style={{ width: "100%", maxWidth: "1300px", margin: "0 auto" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            visibility: query.loading ? "visible" : "hidden",
                        }}
                    >
                        <CircularProgress />
                    </div>
                </div>

                <TicketComplaintsTable ticketComplaints={transactions?.result || []} />

                <div
                    style={{
                        margin: "0 auto",
                        width: "max-content",
                        marginTop: "30px",
                    }}
                >
                    <Pagination
                        count={transactions?.totalPages || 1}
                        page={page}
                        renderItem={(item) => (
                            <PaginationItem component={Link} to={`${basePath}/?page=${item.page}`} {...item} />
                        )}
                    />
                </div>
            </div>
        </div>
    );
});

type TicketComplaintsRow = TicketComplaintFragment;

function TicketComplaintsTable(props: { ticketComplaints: TicketComplaintsQuery["ticketComplaints"]["result"] }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Klage-ID</TableCell>
                        <TableCell>Billett-ID</TableCell>
                        <TableCell>App-Id</TableCell>
                        <TableCell>Mobilnummer</TableCell>
                        <TableCell align={"right"}>Opprinnelig beløp</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align={"right"}>Korrigert beløp</TableCell>
                        <TableCell>Rapportert</TableCell>
                        <TableCell>Behandlet</TableCell>
                        <TableCell>Behandlet av</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.ticketComplaints.map((t) => {
                        const complaint = t as TicketComplaintsRow;
                        return <TicketComplaintRow key={complaint.id} complaint={complaint} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TicketComplaintRow({ complaint }: { complaint: TicketComplaintsRow }) {
    const ticket = complaint.ticket;
    const customer = complaint.ticket.customer;

    return (
        <TableRow>
            <TableCell>
                <Link to={"/ticket2/" + ticket.id + "/complaints"}>{complaint.id}</Link>
            </TableCell>
            <TableCell>
                <Link to={"/ticket2/" + ticket.id + "/complaints"}>{formatTicketId(ticket.id)}</Link>
            </TableCell>
            <TableCell>
                <Link to={"/customer/" + customer.id}>{formatCustomerId(customer.id)}</Link>
            </TableCell>
            <TableCell>
                {(customer.phoneNumberFormatted || "") + (customer.name ? " (" + customer.name + ")" : "")}
            </TableCell>
            <TableCell align={"right"}>{formatMoney(complaint.originalPrice)}</TableCell>
            <TableCell>{formatStatus(complaint)}</TableCell>
            <TableCell align={"right"}>
                {complaint.status == TicketComplaintStatus.Approved ? formatMoney(complaint.correctPrice) : null}
            </TableCell>
            <TableCell>
                <TimestampText value={complaint.createdTime} />
            </TableCell>
            <TableCell>
                <TimestampText
                    value={complaint.status !== TicketComplaintStatus.Pending ? complaint.statusChangedTime : null}
                />
            </TableCell>
            <TableCell>
                {complaint.status !== TicketComplaintStatus.Pending ? complaint.statusChangedByEmail : null}
            </TableCell>
            <TableCell>
                {buildFlagIcon(!complaint.customerEmail, <CallIcon />, "Klagen ble registrert av kundesenter")}
                {buildFlagIcon(ticket.hasTelemetryData, <GpsFixedIcon />, "Har GPS-data")}
                {buildFlagIcon(!!complaint.customerMessage, <EmailIcon />, "E-postbekreftelse sendt")}
            </TableCell>
            <TableCell align={"right"}>
                <JsonDiagnosticButton name={"complaint"} value={complaint} />
            </TableCell>
        </TableRow>
    );
}

function formatStatus({ status, correctPrice, originalPrice }: TicketComplaintsRow): React.JSX.Element {
    let icon: React.JSX.Element;
    let title: string;
    let color: ChipProps["color"];

    switch (status) {
        case TicketComplaintStatus.Pending:
            icon = <HourglassBottomIcon />;
            title = "Venter behandling";
            break;
        case TicketComplaintStatus.Approved:
            if (correctPrice > originalPrice) {
                icon = <ArrowCircleUpIcon />;
                title = "Økt " + formatMoneyShort(correctPrice - originalPrice);
                color = "info"; /// Blue
            } else {
                icon = <ArrowCircleDownIcon />;
                title =
                    correctPrice === 0 ? "Refundert" : "Refundert " + formatMoneyShort(originalPrice - correctPrice);
                color = "success"; /// Green
            }
            break;
        case TicketComplaintStatus.Rejected:
            icon = <RemoveCircleIcon />;
            title = "Avslått";
            color = "error"; /// Red
            break;
    }

    return <Chip icon={icon} label={title} color={color} />;
}
