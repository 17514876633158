import React, { useEffect, useState } from "react";
import LoginPage, { useAuthorizedSuccessRedirect } from "../views/LoginPage";
import { ActiveUserContext, fetchActiveUser, getActiveUser, setActiveUser } from "../util/active_user_context";
import LoadingScreen from "./LoadingScreen";

export function AuthorizedView(props: { children: React.ReactNode }) {
    useAuthorizedSuccessRedirect();
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        fetchActiveUser().then((user) => {
            setActiveUser(user);
            setAuthenticated(true);
        });
    }, []);
    if (!authenticated) return <LoadingScreen />;

    const user = getActiveUser();
    if (!user) return <LoginPage />;

    return <ActiveUserContext.Provider value={user}>{props.children}</ActiveUserContext.Provider>;
}
