import React from "react";
import { CircularProgress } from "@mui/material";

const indicator = (
    <div
        data-testid={"loading-screen"}
        style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <CircularProgress />
    </div>
);

export default React.memo(() => indicator);
