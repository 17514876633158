import {Filters, ISODate} from "../StatisticsProcessingPage";
import React, {useEffect, useRef} from "react";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {bucketize} from "../data_utils";

type Row = [ISODate, number];

export function TicketDurationChart({filters}: { filters: Filters }) {

    const div = useRef(null);
    const data = useJsonData<Array<Row>>(buildUrl("/api/admin/statistics/ticket-duration", filters));

    useEffect(() => {
        if (data != null) {
            const chart = new google.visualization.SteppedAreaChart(div.current!);

            const dataBucketized = bucketize(data, [0, 60, 600, 1200, 3600, 7200]);


            const dataContent: any[][] = [
                ["Date", "<0 min", "1 min", "1-10 min", "10-20 min", "20-60 min", "1-2 hours", ">2 hours"],
                ...dataBucketized
            ];

            chart.draw(
                google.visualization.arrayToDataTable(dataContent),
                {
                    title: 'Ticket duration',
                    // curveType: 'function',
                    isStacked: "percent",
                    legend: {position: 'bottom'},
                    colors: [
                        "#000",
                        "#0F0",
                        "#0A0",
                        "#070",
                        "#070",
                        "#A00",
                        "#600",
                    ]
                }
            );
        }
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;

}

