import React, {useContext, useState} from "react";
import {TicketPageContext} from "../TicketPage";
import {TabbedView_CenterAdjustedContent} from "../../widgets/TabbedView";
import {Button, Typography} from "@mui/material";
import {gql, useMutation} from "@apollo/react-hooks";
import {
    InvoiceWidgetDetailsFragment,
    IssueInvoiceMutation,
    IssueInvoiceMutationVariables
} from "../../generated/gql/graphql";
import LoadingScreen from "../../widgets/LoadingScreen";
import {formatMoney} from "../NetsTransactionsListPage";
import {Link} from "react-router-dom";
import {sum} from "lodash";
import LoadingModal from "../../widgets/LoadingModal";
import {TimestampText} from "../../widgets/date_time";

const INVOICE_DETAILS_FRAGMENT = gql`

    fragment InvoiceWidgetDetails on Invoice {
        invoiceNumber
        invoiceDate
        deliveryDate
        ticket {
            id
            createdTime
            legsDescription
            price
            basePrice
        }
        creditedBy {
            invoiceNumber
        }
        creditFor {
            invoiceNumber
        }


        amountExcludingVat
        vatAmount
        totalAmount

        lines {
            productDescriptionNorwegian
            unitPriceExcludingVat
            undiscountedAmountIncludingVat
            vatAmount
            totalAmount
        }

        transaction {
            id
            processedSuccessfullyTime
        }

        createdTime
    }


`;

export {INVOICE_DETAILS_FRAGMENT};

const ISSUE_INVOICE_MUTATION = gql`
    mutation IssueInvoice($ticketId: Int!){
        issueInvoice(ticketId: $ticketId){
            invoiceNumber
        }
    }
`;


export default function InvoicesView() {
    const query = useContext(TicketPageContext)!;
    const issueMutation = useMutation<IssueInvoiceMutation, IssueInvoiceMutationVariables>(ISSUE_INVOICE_MUTATION, {variables: {ticketId: query.data?.ticket?.id!}});
    const [modal, setModal] = useState<React.ReactNode>(null);


    if (query.loading)
        return <LoadingScreen/>;

    const invoices = query.data!.ticket.invoices as InvoiceWidgetDetailsFragment[];

    const issueInvoice = () => {
        setModal(<LoadingModal/>);

        issueMutation[0]()
            .then(() => query.refetch())
            .then(() => setModal(null))
    }

    return <TabbedView_CenterAdjustedContent>

        <Button variant={"outlined"} onClick={() => issueInvoice()}>
            Opprett faktura
        </Button>


        <Button variant={"outlined"} component={"a"}
                href={"/api/admin/ticket/month_receipt?ticketId=" + query.data!.ticket.id}>
            Månedsfaktura
        </Button>


        {invoices.map((invoice) => <InvoiceWidget key={invoice.invoiceNumber} invoice={invoice}/>)}
        {modal}
    </TabbedView_CenterAdjustedContent>;
}

const InvoiceWidget = (props: { invoice: InvoiceWidgetDetailsFragment }) => {
    const invoice = props.invoice;
    const transaction = invoice.transaction;
    const undiscountedAmount = sum(invoice.lines.map((l) => l.undiscountedAmountIncludingVat));
    const creditStyle = invoice.creditFor || invoice.creditedBy ? {textDecoration: "line-through"} : {};

    return <div style={{
        border: "1px solid black",
        ...creditStyle,
        marginBottom: "10px"
    }}>
        <Typography variant={"h6"}>
            {invoice.creditFor ? "Kreditnota" : "Faktura"} {invoice.invoiceNumber}
        </Typography>
        <Typography variant={"body2"}>
            Invoice date: {invoice.invoiceDate}
        </Typography>
        <Typography variant={"body2"}>
            Invoice created time: <TimestampText value={invoice.createdTime}/>
        </Typography><br/><br/>
        <Typography variant={"body2"}>
            <Link to={"/ticket/" + invoice.ticket.id}>
                Ticket {invoice.ticket.id}
            </Link><br/>
            Created time: <TimestampText value={invoice.ticket.createdTime}/><br/>
            Price: {formatMoney(invoice.ticket.price)}/{formatMoney(invoice.ticket.basePrice)}<br/><br/>
        </Typography>

        <Typography variant={"body2"}>
            {transaction
                ? <Link to={"/nets-transaction/" + transaction.id}>
                    {transaction.processedSuccessfullyTime != null
                        ? <span>BETALT <TimestampText
                            value={transaction.processedSuccessfullyTime}
                        />
                    </span>
                        : "BETALING FEILET"}
                </Link>
                : "VENTER PÅ RECONCILIATION"}
        </Typography>


        <table width={"100%"}>
            <thead>
            <tr>
                <th>Beskrivelse</th>
                <th>Urabattert (kr ink.mva)</th>
                <th>Total (kr ink.mva)</th>
            </tr>
            </thead>
            <tbody>
            {invoice.lines.map(line => <tr>
                <td>{line.productDescriptionNorwegian.replaceAll("[b]", "").replaceAll("[/b]", "")}</td>
                <td align={"right"}>{formatMoney(line.undiscountedAmountIncludingVat)}</td>
                <td align={"right"}>{formatMoney(line.totalAmount)}</td>
            </tr>)}

            <tr>
                <td></td>
                <td align={"right"}>{formatMoney(undiscountedAmount)}</td>
                <td align={"right"}>{formatMoney(invoice.totalAmount)}</td>
            </tr>
            </tbody>
        </table>
    </div>;
}

export {InvoiceWidget};
