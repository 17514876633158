import React, {useContext, useState} from "react";
import {TicketPageContext} from "../TicketPage";
import {DateTimeFormat} from "../../util/date_time";
import {formatVehiclePosition} from "../../util/active_ticket_state";
import {ActiveTicketStateDetailsFragment} from "../../generated/gql/graphql";
import {formatPercentage} from "../../util/formatting";
import {Grid, Paper, Typography, Link as MaterialLink} from "@mui/material";
import {TimestampText} from "../../widgets/date_time";


export function TicketActiveTicketStateLogView() {
    const query = useContext(TicketPageContext)!;

    const list = query.data!.ticket.activeTicketStateLog;

    return <div>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper>
                    <Typography variant={"h6"}>
                        Active ticket state
                    </Typography>

                    <table>
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Description</th>
                            <th>Error</th>
                            <th>Raw</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((s, index) => {
                            const state = s as ActiveTicketStateDetailsFragment;
                            return <tr key={index}>
                                <td><TimestampText format={DateTimeFormat.TIME} value={state.timestamp} /></td>
                                <td>{state.mode}</td>
                                <td>{state.errorMessage + "\n" + state.stackTrace}</td>
                                <td><RawProtobufExpandable json={state.rawProtobufJson} /></td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper>
                    <Typography variant={"h6"}>
                        Active vehicle detected
                    </Typography>

                    <table>
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Transport mode</th>
                            <th>Vehicle</th>
                            <th>Confidence</th>
                            <th>End confidence</th>
                            <th>Json</th>
                        </tr>
                        </thead>
                        <tbody>
                        {query.data!.ticket.activeVehicleDetectedLog.map((s) => {
                            return <tr>
                                <td><TimestampText format={DateTimeFormat.TIME} value={s.recordedTime} /></td>
                                <td>{s.recognizedTransportMode}</td>
                                <td>{formatVehiclePosition(s.vehiclePosition)}</td>
                                <td align={"right"}>{formatPercentage(s.confidence) + ")"}</td>
                                <td align={"right"}>{formatPercentage(s.endConfidence)}</td>
                                <td><RawProtobufExpandable json={s.rawProtobufJson} /></td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </Paper>
            </Grid>
        </Grid>
    </div>;
}

function RawProtobufExpandable(props: {json: string}): React.ReactElement {
    const [expanded, setExpanded] = useState(false);

    if (props.json) {
        return <MaterialLink onClick={() => setExpanded(!expanded)}>
            {expanded ? <pre>{props.json}</pre> : "Expand"}
        </MaterialLink>;
    } else {
        return <>null</>;
    }
}