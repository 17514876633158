import { DateTime } from "luxon";
import { parseTime } from "./date_time";
import { GraphQLScalarType, Kind } from "graphql";
import type { ValueNode } from "graphql/language/ast";
import { ApolloClient, InMemoryCache } from "@apollo/client";

const isoTimestampScalar = new GraphQLScalarType<DateTime, string>({
    name: "ISOTimestamp",
    description: "Date custom scalar type",
    serialize: (value: unknown): string => {
        if (typeof value === "string") {
            return value;
        } else {
            const typedValue = value as DateTime;
            return typedValue.toISO() as string;
        }
    },
    parseValue: (value) => {
        return parseTime(value as string) as DateTime;
    },
    parseLiteral: (ast: ValueNode): DateTime => {
        if (ast.kind === Kind.STRING) {
            // Convert hard-coded AST string to integer and then to Date
            return parseTime(ast.value);
        } else {
            throw new Error("Bad ISOTimestamp value:" + ast);
        }
    },
});

export function createApolloClient(): ApolloClient<any> {
    return new ApolloClient({
        uri: "/graphql",
        credentials: "include",
        cache: new InMemoryCache(),
        // @ts-ignore
        resolvers: {
            ISOTimestamp: isoTimestampScalar,
        },
    });
}
