import React, {useEffect, useRef} from "react";
import {useJsonData} from "../../../util/script_loader";
import {ISOTimestamp} from "../StatisticsProcessingPage";
import {NOK} from "../data";
import {toIsoDate} from "./TicketLegTransportModeChart";
import {sum} from "lodash";

type Row = [
    createdTime: ISOTimestamp,
    amount: NOK,
    amountCaptured: NOK
]

export function NetsTransactionAmountChart() {
    const data = useJsonData<Array<Row>>("/api/admin/statistics/transactions");

    if (data != null) {
        return <div>
            <DateAmountChart data={data}/>
            <DateAmountMissingChart data={data}/>
        </div>;
    } else {
        return <div>Loading</div>;
    }

}

type Props = { data: ReadonlyArray<Row> };

function DateAmountChart({data}: Props) {
    const div = useRef(null);

    useEffect(() => {
        const chart = new google.visualization.LineChart(div.current!);

        chart.draw(
            google.visualization.data.group(
                google.visualization.arrayToDataTable([
                    ["Date", "Amount", "Amount captured"],
                    ...data
                ]),
                [{
                    column: 0,
                    type: "string",
                    modifier: toIsoDate,
                }],
                [
                    {
                        column: 1,
                        type: "number",
                        label: "Amount (kr)",
                        aggregation: (values: any[]) => {
                            return sum(values) / 100;
                        }
                    },
                    {
                        column: 2,
                        type: "number",
                        label: "Amount captured (kr)",
                        aggregation: (values: any[]) => {
                            return sum(values) / 100;
                        }
                    },
                ]
            ),
            {
                title: 'Nets transactions',
                curveType: 'function',
                legend: {position: 'bottom'}
            }
        );
    }, [data]);

    return <div ref={div} style={{height: 400}}/>;
}


function DateAmountMissingChart({data}: Props) {
    const div = useRef(null);

    useEffect(() => {
        const chart = new google.visualization.LineChart(div.current!);

        chart.draw(
            google.visualization.data.group(
                google.visualization.arrayToDataTable([
                    ["Date", "Amount missing"],
                    ...data.map((e) => ([e[0], e[1] - e[2]])),
                ]),
                [{
                    column: 0,
                    type: "string",
                    modifier: toIsoDate,
                }],
                [
                    {
                        column: 1,
                        type: "number",
                        label: "Amount missing (kr)",
                        aggregation: (values: any[]) => {
                            return sum(values) / 100;
                        }
                    },
                ]
            ),
            {
                title: 'Nets amount missing',
                curveType: 'function',
                legend: {position: 'bottom'}
            }
        );
    }, [data]);

    return <div ref={div} style={{height: 400}}/>;
}

