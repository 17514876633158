import React, { memo, useState } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import {
    NetsTransactionFragment,
    NetsTransactionsQuery,
    NetsTransactionsQueryVariables,
} from "../generated/gql/graphql";
import { Link, useLocation } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { usePageTitle } from "../util/page_title";
import { formatCustomerId, formatTicketId } from "../util/formatting";
import { TransactionNetsResponseText } from "./ticket_v2/TicketInfoTab";
import { TimestampText } from "../widgets/date_time";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { buildFlagIcon } from "./FrontPage";
import WarningIcon from "@mui/icons-material/Warning";
import PublicIcon from "@mui/icons-material/Public";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { sortBy } from "lodash";
import { JsonDiagnosticButton } from "../widgets/diagnostics";

export const NETS_TRANSACTION_FRAGMENT = gql`
    fragment NetsTransaction on NetsTransaction {
        id
        netsTransactionId
        savedCard {
            id
            netsTransactionId
            maskedPan
            issuer
            issuerCountry
            isActive
        }
        customer {
            id
            name
            phoneNumberFormatted
        }

        credits {
            id
        }

        createdTime
        processedSuccessfullyTime
        amount
        amountCaptured
        netsCaptureParsedResponseCode
        netsCaptureResponseCode
        netsCaptureResponseSource
        netsCaptureResponseText

        invoices {
            invoiceNumber
            ticket {
                id
                createdTime
            }
            descriptionNorwegian
            totalAmount
            createdTime
        }
        credits {
            id

            netsProcessParsedResponseCode
            netsProcessResponseCode
            netsProcessResponseSource
            netsProcessResponseText
            invoices {
                invoiceNumber
                ticket {
                    id
                    createdTime
                }
                descriptionNorwegian
                totalAmount
                createdTime
            }
            createdTime
        }
    }
`;

const NETS_TRANSACTIONS_QUERY = gql`
    query NetsTransactions($query: String!, $page: Int!, $limit: Int!) {
        netsTransactions(query: $query, page: $page, limit: $limit) {
            totalPages
            result {
                ...NetsTransaction
            }
        }
    }
    ${NETS_TRANSACTION_FRAGMENT}
`;

export function formatMoney(amount: number | null | undefined): string {
    if (amount === null || amount === undefined) return "–";
    if (amount < 0) return "- " + formatMoney(-amount as number);

    if (amount % 100 === 0) return amount / 100 + " kr";
    return (amount / 100).toFixed(2) + " kr";
}

export function formatMoneyShort(amount: number | null | undefined): string {
    return formatMoney(amount);
}

export const NetsTransactionsListPage = memo(() => {
    usePageTitle("Kortbetalinger");
    const [searchQuery, setSearchQuery] = useState("");
    const basePath = "/nets-transactions";

    const location = useLocation();
    const locationQuery = new URLSearchParams(location.search);
    const page = parseInt(locationQuery.get("page") || "0", 10) || 1;
    const query = useQuery<NetsTransactionsQuery, NetsTransactionsQueryVariables>(NETS_TRANSACTIONS_QUERY, {
        variables: {
            query: searchQuery,
            page: page - 1,
            limit: 40,
        },
    });
    const transactions = (query.data || query.previousData)?.netsTransactions;

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <div style={{ width: "100%", maxWidth: "1300px", margin: "0 auto" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            visibility: query.loading ? "visible" : "hidden",
                        }}
                    >
                        <CircularProgress />
                    </div>

                    <div style={{ width: "400px", margin: "40px 20px" }}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={"Søk etter app-ID, mobilnummer, transaksjons-id eller ordre-nummer"}
                            variant={"filled"}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>

                <NetsTransactionsTable showCardInfo={true} transactions={transactions?.result || []} />

                <div
                    style={{
                        margin: "0 auto",
                        width: "max-content",
                        marginTop: "30px",
                    }}
                >
                    <Pagination
                        count={transactions?.totalPages || 1}
                        page={page}
                        renderItem={(item) => (
                            <PaginationItem component={Link} to={`${basePath}/?page=${item.page}`} {...item} />
                        )}
                    />
                </div>
            </div>
        </div>
    );
});

type NetsTransactionRow = NetsTransactionFragment;

export function NetsTransactionsTable(props: {
    showCardInfo: boolean;
    transactions: NetsTransactionsQuery["netsTransactions"]["result"];
}) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Kvitterings-ID</TableCell>
                        <TableCell>Nets Transaction ID</TableCell>
                        {props.showCardInfo ? (
                            <React.Fragment>
                                <TableCell>App-Id</TableCell>
                                <TableCell>Mobilnummer</TableCell>
                                <TableCell>Kortnummer</TableCell>
                            </React.Fragment>
                        ) : null}
                        <TableCell align={"right"}>Beløp</TableCell>
                        <TableCell>Nets Respons kode</TableCell>
                        <TableCell>Tidspunkt</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.transactions.map((t) => {
                        const transaction = t as NetsTransactionRow;
                        return (
                            <TransactionRow
                                key={transaction.id}
                                showCardInfo={props.showCardInfo}
                                transaction={transaction}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TransactionRow({ showCardInfo, transaction }: { showCardInfo: boolean; transaction: NetsTransactionRow }) {
    const [open, setOpen] = React.useState(false);
    const invoices = sortBy(transaction.invoices, "invoiceNumber");
    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Link to={"/nets-transaction/" + transaction.id}>{transaction.id}</Link>
                </TableCell>
                <TableCell>
                    <Link to={"/nets-transaction/" + transaction.id}>{transaction.netsTransactionId}</Link>
                </TableCell>
                {showCardInfo ? (
                    <React.Fragment>
                        <TableCell>
                            <Link to={"/customer/" + transaction.customer.id}>
                                {formatCustomerId(transaction.customer.id)}
                            </Link>
                        </TableCell>
                        <TableCell>
                            {(transaction.customer.phoneNumberFormatted || "") +
                                (transaction.customer.name ? " (" + transaction.customer.name + ")" : "")}
                        </TableCell>
                        <TableCell align={"right"}>{formatSavedCard(transaction.savedCard)}</TableCell>
                    </React.Fragment>
                ) : null}
                <TableCell align={"right"}>{formatMoney(transaction.amount)}</TableCell>
                <TableCell>
                    <TransactionNetsResponseText transaction={transaction} />
                </TableCell>
                <TableCell>
                    <TimestampText value={transaction.createdTime} />
                </TableCell>
                <TableCell>
                    <JsonDiagnosticButton name={"transaction"} value={transaction} />
                    {buildFlagIcon(
                        transaction.amountCaptured == 0 && !transaction.savedCard.isActive,
                        <WarningIcon />,
                        "Brukeren har byttet kort etter denne betalingen",
                    )}
                    {buildFlagIcon(transaction.credits.length > 0, <CreditCardOffIcon />, "Kredittert")}
                    {buildFlagIcon(
                        transaction.savedCard.issuerCountry != "NO",
                        <PublicIcon />,
                        "Utstederland: " + transaction.savedCard.issuerCountry,
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Billetter
                            </Typography>
                            <Table width={"auto"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Løpenummer</TableCell>
                                        <TableCell>Billett-Id</TableCell>
                                        <TableCell>Kjøpt</TableCell>
                                        <TableCell>Beskrivelse</TableCell>
                                        <TableCell>Faktura endret dato</TableCell>
                                        <TableCell align={"right"}>Beløp</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {invoices.map((invoice) => (
                                        <TableRow key={invoice.invoiceNumber}>
                                            <TableCell>{invoice.invoiceNumber}</TableCell>
                                            <TableCell>
                                                <Link to={"/ticket2/" + invoice.ticket.id}>
                                                    {formatTicketId(invoice.ticket.id)}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <TimestampText value={invoice.ticket.createdTime} />
                                            </TableCell>
                                            <TableCell>{invoice.descriptionNorwegian}</TableCell>
                                            <TableCell>
                                                <TimestampText value={invoice.createdTime} />
                                            </TableCell>
                                            <TableCell align={"right"}>{formatMoney(invoice.totalAmount)}</TableCell>
                                        </TableRow>
                                    ))}
                                    {transaction.credits.map((credit) => {
                                        return credit.invoices.map((invoice) => {
                                            return (
                                                <TableRow key={credit.id}>
                                                    <TableCell>{invoice.invoiceNumber}</TableCell>
                                                    <TableCell>
                                                        <Link to={"/ticket2/" + invoice.ticket.id}>
                                                            {formatTicketId(invoice.ticket.id)}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TimestampText value={invoice.ticket.createdTime} />
                                                    </TableCell>
                                                    <TableCell>{invoice.descriptionNorwegian}</TableCell>
                                                    <TableCell>
                                                        <TimestampText value={invoice.createdTime} />
                                                    </TableCell>
                                                    <TableCell align={"right"}>
                                                        {formatMoney(invoice.totalAmount)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <TransactionNetsResponseText transaction={credit} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        });
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function formatSavedCard(card: { issuer: string; maskedPan: string }) {
    let cardNumber = card.maskedPan;
    if (cardNumber.length > 4) cardNumber = cardNumber.substring(cardNumber.length - 4);

    return card.issuer + " " + cardNumber;
}
