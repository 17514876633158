import { TicketPageHeaderFragment } from "../../generated/gql/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { Alert, Grid, Link as MaterialLink, Typography } from "@mui/material";
import { TimestampText } from "../../widgets/date_time";
import { formatMoney } from "../NetsTransactionsListPage";
import { DateTimeFormat } from "../../util/date_time";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import { formatCustomerId, formatTicketId } from "../../util/formatting";
import { isSuperUser } from "../../util/active_user_context";
import gql from "graphql-tag";
import { SendReceiptEmailButton } from "./SendReceiptEmailButton";
import { JsonDiagnosticButton } from "../../widgets/diagnostics";
import { Duration } from "luxon";

type TicketPageHeaderProps = {
    ticket: TicketPageHeaderFragment;
};

export const TICKET_PAGE_HEADER_FRAGMENT = gql`
    fragment TicketPageHeader on Ticket {
        id
        createdTime
        endedTime
        endedReason

        discount
        price
        basePrice
        processedTime
        invoicedTime

        telemetryDataUploadConsentGiven
        telemetryDataUploadRequired

        automaticPurchase
        purchaseRequestPhoneTime
        statusPaymentText

        passengerTypeInfo {
            textDescription
            passengerTypeAddons
        }

        customer {
            id
            displayName
            phoneNumber
            phoneNumberFormatted
            deletionRequestedTime
        }

        clockDeviationEstimate {
            timeOffsetMillis
            minDeviationMillis
            maxDeviationMillis
        }
    }
`;

function PhoneTimeSynchronizationWarning({ ticket }: TicketPageHeaderProps) {
    const timeOffsetMillis = ticket.clockDeviationEstimate.timeOffsetMillis || 0;
    const diff = Duration.fromMillis(timeOffsetMillis);

    if (Math.abs(diff.as("seconds")) < 10) {
        return;
    }

    return (
        <Alert severity="error" style={{ marginBottom: "20px" }}>
            <Typography variant={"subtitle1"}>
                <b>Advarsel:</b> Mobilen sin innebygde klokke var stilt {formatDeviation(timeOffsetMillis)}.
            </Typography>
            <Typography variant={"body2"}>Dette medfører høyere sannsynlighet for analysefeil.</Typography>
            {isSuperUser() ? (
                <Typography variant={"body2"}>
                    Estimert avvik (minimum): {formatDeviation(ticket.clockDeviationEstimate.minDeviationMillis)}
                    <br />
                    Estimert avvik (maximum): {formatDeviation(ticket.clockDeviationEstimate.maxDeviationMillis)}
                </Typography>
            ) : null}
        </Alert>
    );
}

function formatDeviation(millis: number | null | undefined) {
    if (millis === null || millis === undefined) return "?";
    let string = Math.abs(millis / 1000).toFixed(2);

    if (millis > 0) {
        string += " sekunder for langt fram";
    } else {
        string += " sekunder for langt bak";
    }

    return string;
}

export function TicketPageHeader({ ticket }: TicketPageHeaderProps) {
    return (
        <div>
            <h2>
                Billett: {formatTicketId(ticket.id)}
                <JsonDiagnosticButton name={"ticket"} value={ticket} />
            </h2>

            {ticket.customer.deletionRequestedTime ? (
                <Alert severity="error" style={{ marginBottom: "20px" }}>
                    Kunden deaktiverte kontoen sin <TimestampText value={ticket.customer.deletionRequestedTime} />, og
                    denne siden vil derfor ha begrensede opplysninger om brukerens reise.
                </Alert>
            ) : null}

            <PhoneTimeSynchronizationWarning ticket={ticket} />

            {ticket.automaticPurchase ? (
                <Alert color={"error"} style={{ marginBottom: "20px" }}>
                    Billetten ble <b>automatisk startet</b> da brukeren trykket på "Start reise"-knappen.
                </Alert>
            ) : null}

            <div style={{ marginBottom: "40px" }}>
                <SendReceiptEmailButton ticketId={ticket.id} />
            </div>

            <Grid container spacing={2} direction={"row"}>
                <Grid item xs={6} md={6}>
                    <PropertyList>
                        <Property title={"Billettype"} value={ticket.passengerTypeInfo.textDescription} />

                        <Property
                            title={"Antall reisende"}
                            value={ticket.passengerTypeInfo.passengerTypeAddons.length + 1}
                        />

                        <Property
                            title={"Status"}
                            value={""} /// TODO: Implement
                        />

                        <Property
                            title={"App-ID"}
                            value={
                                <Link to={"/customer/" + ticket.customer.id}>
                                    {formatCustomerId(ticket.customer.id)}
                                </Link>
                            }
                        />

                        <Property
                            title={"Mobilnummer"}
                            value={
                                ticket.customer.phoneNumber ? (
                                    <MaterialLink href={"tel:" + ticket.customer.phoneNumber}>
                                        {ticket.customer.phoneNumberFormatted}
                                    </MaterialLink>
                                ) : (
                                    "-"
                                )
                            }
                        />

                        <Property
                            title={"Telemetri-data"}
                            value={
                                ticket.telemetryDataUploadConsentGiven
                                    ? "Bruker har samtykket til opplasting av telemetri-data"
                                    : "Mangler samtykke til opplasting av telemetri-data"
                            }
                        />
                        {ticket.telemetryDataUploadRequired ? (
                            <Typography>Opplasting av telemetri-data er påkrevd for denne billetten!.</Typography>
                        ) : null}
                    </PropertyList>
                </Grid>
                <Grid item xs={6} md={6}>
                    <PropertyList>
                        <Property
                            title={"Gyldig fra / Bestilt dato"}
                            value={<TimestampText value={ticket.createdTime} format={DateTimeFormat.DATETIME_LONG} />}
                        />
                        <Property
                            title={"Gyldig til"}
                            value={<TimestampText value={ticket.endedTime} format={DateTimeFormat.DATETIME_LONG} />}
                        />
                        <Property title={"Avsluttingsgrunn"} value={ticket.endedReason} />

                        <Property title={"Pris"} value={formatMoney(ticket.price)} />
                        <Property title={"Urabattert pris"} value={formatMoney(ticket.basePrice)} />
                        <Property title={"Rabatt"} value={ticket.discount + "%"} />
                        <Property title={"Betaling"} value={ticket.statusPaymentText} />
                    </PropertyList>
                </Grid>
            </Grid>
        </div>
    );
}

export function PropertyList(props: { children: React.ReactNode }) {
    return <div>{props.children}</div>;
}

export function Property(props: {
    vertical?: boolean;
    requiresSuperUser?: boolean;
    title: React.ReactNode;
    value: React.ReactNode;
}) {
    if (props.requiresSuperUser && !isSuperUser()) {
        return null;
    }

    return (
        <div style={{ display: "flex", marginBottom: "8px", flexDirection: props.vertical ? "column" : undefined }}>
            <div style={{ minWidth: "155px", paddingRight: "10px" }}>
                {props.title}
                {props.requiresSuperUser ? <DeveloperModeIcon fontSize={"small"} /> : null}
            </div>
            <div>{props.value}</div>
        </div>
    );
}
