import React, { memo } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import LoadingScreen from "../widgets/LoadingScreen";
import { Tab, Tabs } from "@mui/material";
import {
    CustomerPageQuery,
    CustomerPageQueryVariables,
    DeviceInfoFragment,
    TicketRowFragment,
} from "../generated/gql/graphql";
import { usePageTitle } from "../util/page_title";
import { CustomerTicketTable, TICKET_ROW_FRAGMENT } from "./FrontPage";
import { CustomerPageHeader } from "./customer/CustomerPageHeader";
import { DEVICE_INFO_FRAGMENT } from "./ticket_v2/TicketAppTab";
import { CustomTabPanel } from "./TicketPageV2";
import { CUSTOMER_SAVED_CARDS_FRAGMENT, CustomerSavedCardsTab } from "./customer/CustomerSavedCardsTab";
import { CustomerAppTab } from "./customer/CustomerAppTab";
import { CrashlyticsTab } from "../widgets/CrashlyticsTab";
import { isSuperUser } from "../util/active_user_context";

type Customer = CustomerPageQuery["customer"];
const CUSTOMER_PAGE_QUERY = gql`
    query CustomerPage($customerId: Int!) {
        customer(id: $customerId) {
            id
            name
            displayName
            phoneNumber
            phoneNumberFormatted
            researchGroup
            activeResearchGroup
            createdTime
            deletionRequestedTime

            hasHJH
            passengerType
            discount
            telemetryDataUploadConsentGiven

            tickets {
                ...TicketRow
            }

            paymentReconciliations {
                ...PaymentReconciliation
            }

            savedCards {
                ...CustomerSavedCard
            }

            deviceInfo {
                ...DeviceInfo
            }
        }
    }

    ${TICKET_ROW_FRAGMENT}
    ${DEVICE_INFO_FRAGMENT}
    ${CUSTOMER_SAVED_CARDS_FRAGMENT}
`;

export default memo(() => {
    const customerId = useParams<{ customerId: string }>().customerId!;
    const query = useQuery<CustomerPageQuery, CustomerPageQueryVariables>(CUSTOMER_PAGE_QUERY, {
        variables: { customerId: parseInt(customerId) },
    });
    const basePath = "/customer/" + customerId;
    const page = window.location.hash.replace("#/customer/" + customerId, "").replace("/", "");
    console.log(page);

    usePageTitle(query.data?.customer?.displayName || "Kunde...");

    if (query.loading) return <LoadingScreen />;

    const { customer } = query.data!;

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <div
                style={{
                    width: "100%",
                    padding: "20px",
                    maxWidth: "1400px",
                    margin: "0 auto",
                }}
            >
                <CustomerPageHeader customer={customer as Customer} />

                <Tabs value={page}>
                    <Tab value="" label={"Billetter"} component={Link} to={basePath} />
                    <Tab value="cards" label={"Kort"} component={Link} to={basePath + "/cards"} />
                    <Tab value="app" label={"App"} component={Link} to={basePath + "/app"} />

                    <div style={{ width: "100px" }} />
                    {isSuperUser() ? (
                        <CrashlyticsTab
                            customerId={customer.id}
                            firebaseAppName={(customer.deviceInfo as DeviceInfoFragment)?.firebaseAppName}
                        />
                    ) : null}
                </Tabs>

                <div style={{ border: "1px solid #DDD" }}>
                    <CustomTabPanel page={""} selectedPage={page || ""}>
                        <CustomerTicketTable
                            tickets={customer.tickets as unknown as ReadonlyArray<TicketRowFragment>}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel page={"cards"} selectedPage={page || ""}>
                        <CustomerSavedCardsTab customer={customer} />
                    </CustomTabPanel>

                    <CustomTabPanel page={"app"} selectedPage={page || ""}>
                        <CustomerAppTab customer={customer} />
                    </CustomTabPanel>
                </div>
            </div>
        </div>
    );
});
