import gql from "graphql-tag";
import {
    CrashlyticsErrorType,
    DeviceInfoFragment,
    TicketCrashlyticsQuery,
    TicketCrashlyticsQueryVariables,
    TicketPageV2Query,
} from "../../generated/gql/graphql";
import { useQuery } from "@apollo/client";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { RelativeTimeText } from "../../widgets/date_time";
import { sortBy } from "lodash";
import LaunchIcon from "@mui/icons-material/Launch";
import BugReportIcon from "@mui/icons-material/BugReport";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WarningIcon from "@mui/icons-material/Warning";
import { grey, red, yellow } from "@mui/material/colors";
import { buildCrashlyticsLink } from "../../widgets/CrashlyticsTab";

const TICKET_CRASHLYTICS_QUERY = gql`
    query TicketCrashlytics($ticketId: Int!) {
        ticket(id: $ticketId) {
            crashlyticsInfo {
                crashes {
                    eventId
                    eventTimestamp

                    errorType

                    issueId
                    issueTitle
                    issueSubtitle

                    customKeys

                    ticketId
                    consoleUrl
                }
            }
        }
    }
`;

type Ticket = TicketPageV2Query["ticket"];

interface TicketCrashlyticsTabProps {
    ticket: Ticket;
}

export function TicketCrashlyticsTab(props: TicketCrashlyticsTabProps) {
    const query = useQuery<TicketCrashlyticsQuery, TicketCrashlyticsQueryVariables>(TICKET_CRASHLYTICS_QUERY, {
        variables: {
            ticketId: props.ticket.id,
        },
    });

    if (query.loading) return <Typography>Loading...</Typography>;

    const crashlyticsInfo = query.data!.ticket.crashlyticsInfo!;

    const crashes = sortBy(crashlyticsInfo.crashes, "eventTimestamp");

    return (
        <div>
            <Button
                variant={"contained"}
                color={"primary"}
                startIcon={<LaunchIcon />}
                href={buildCrashlyticsLink({
                    customerId: props.ticket.customer.id,
                    firebaseAppName: (props.ticket.deviceInfo as DeviceInfoFragment)?.firebaseAppName,
                    fromTime: props.ticket.createdTime,
                })}
                target={"_blank"}
            >
                Open in Crashlytics
            </Button>

            <div style={{ height: "40px" }} />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Error Type</TableCell>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell>Issue title</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {crashes.map((c) => (
                        <TableRow key={c.eventId}>
                            <TableCell>
                                <ErrorTypeIcon errorType={c.errorType} />
                            </TableCell>
                            <TableCell>
                                <RelativeTimeText
                                    showTimeDifference
                                    value={c.eventTimestamp}
                                    relativeTo={props.ticket.createdTime}
                                />
                            </TableCell>
                            <TableCell>{c.ticketId ? "Ticket" : "Customer"}</TableCell>
                            <TableCell>
                                <Typography variant={"subtitle1"}>{c.issueTitle}</Typography>
                                <Typography variant={"body2"}>{c.issueSubtitle}</Typography>
                            </TableCell>
                            <TableCell>
                                <IconButton component={"a"} href={c.consoleUrl} target={"_blank"}>
                                    <LaunchIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

function ErrorTypeIcon(props: { errorType: CrashlyticsErrorType }) {
    let color: string;
    let icon: React.ReactNode;
    let text: string;

    switch (props.errorType) {
        case CrashlyticsErrorType.Fatal:
            color = red[600];
            icon = <BugReportIcon />;
            text = "Fatal";
            break;
        case CrashlyticsErrorType.NonFatal:
            color = yellow[900];
            icon = <WarningIcon />;
            text = "Non-fatal";
            break;
        case CrashlyticsErrorType.Anr:
            color = grey[600];
            icon = <HourglassEmptyIcon />;
            text = "ANR";
            break;
    }

    return (
        <Button variant={"contained"} startIcon={icon} style={{ whiteSpace: "nowrap", backgroundColor: color }}>
            {text}
        </Button>
    );
}
