import {AppVersionName, Filters, ISOTimestamp} from "../StatisticsProcessingPage";
import React, {useEffect, useRef} from "react";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {DevicePlatform} from "../../../generated/gql/graphql";
import {groupByColumnWithCount, GroupInputRow} from "./TicketEndedReasonChart";
import {toIsoDate} from "./TicketLegTransportModeChart";
import {filterData} from "./filters";

type ProcessPlatform = "Unknown" | "LocalPrice" | "None"

export type TicketStatisticRow = [
    ticketCreatedTime: ISOTimestamp,
    platform: DevicePlatform | null,
    appVersion: AppVersionName | null,
    processPlatform: ProcessPlatform,
    legCount: number,
    price: number,
    basePrice: number
];
type Row = TicketStatisticRow;

export function TicketProcessPlatformChart({filters}: { filters: Filters }) {
    const data = useJsonData<Array<Row>>(buildUrl("/api/admin/statistics/ticket-statistics", filters));


    if (data != null) {
        const filtered = filterData<Row>(filters, data);

        return <div>
            <LegCountChart data={filtered}/>
            <PlatformChart data={filtered}/>
        </div>
    } else {
        return <div>Loading</div>;
    }

}

type Props = { data: ReadonlyArray<Row> }

function LegCountChart({data}: Props) {


    const div = useRef(null);

    useEffect(() => {
        const chart = new google.visualization.SteppedAreaChart(div.current!);

        const dataContent: any[][] = [
            // ["Date", "0 legs", "1 leg", "2 leg", "3+ legs"],
            ["Date", "Leg count"],
            ...data.map((e) => {
                const legCount = e[4];
                return [toIsoDate(e[0]), legCount];
                // return [toIsoDate(e[0]), legCount == 0, legCount == 1, legCount == 2, legCount >= 3];
            }),
        ];

        chart.draw(
            google.visualization.data.group(
                google.visualization.arrayToDataTable(dataContent),
                [0],
                [
                    {
                        column: 1,
                        label: "0 legs",
                        type: "number",
                        aggregation: (legCounts: number[]) => legCounts.filter((c) => c === 0).length,
                    },
                    {
                        column: 1,
                        label: "1 leg",
                        type: "number",
                        aggregation: (legCounts: number[]) => legCounts.filter((c) => c === 1).length,
                    },
                    {
                        column: 1,
                        label: "2 legs",
                        type: "number",
                        aggregation: (legCounts: number[]) => legCounts.filter((c) => c === 2).length,
                    },
                    {
                        column: 1,
                        label: "3 legs",
                        type: "number",
                        aggregation: (legCounts: number[]) => legCounts.filter((c) => c === 3).length,
                    },
                    {
                        column: 1,
                        label: "4+ legs",
                        type: "number",
                        aggregation: (legCounts: number[]) => legCounts.filter((c) => c >= 4).length,
                    }
                ]
            ),
            {
                title: 'Leg count',
                // curveType: 'function',
                isStacked: "percent",
                legend: {position: 'bottom'},
            }
        );
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;
}

function PlatformChart({data}: Props) {

    const div = useRef(null);

    useEffect(() => {
        const chart = new google.visualization.SteppedAreaChart(div.current!);
        const processPlatform: ProcessPlatform[] = [
            "LocalPrice",
            "Unknown",
            "None"
        ];

        const dataContent: any[][] = [
            ["Date", ...processPlatform],
            ...groupByProcessPlatform(data, processPlatform)
        ];

        chart.draw(
            google.visualization.arrayToDataTable(dataContent),
            {
                title: 'Ticket processing platform',
                // curveType: 'function',
                isStacked: "percent",
                legend: {position: 'bottom'},
            }
        );
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;

}

function groupByProcessPlatform(data: ReadonlyArray<Row>, processPlatforms: ProcessPlatform[]): any[][] {
    const input: GroupInputRow<ProcessPlatform>[] = data.map((e) => [toIsoDate(e[0]), e[3], 1]);
    return groupByColumnWithCount(input, processPlatforms);
}