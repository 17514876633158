import {Typography} from "@mui/material";
import {formatTimeRange, parseTime} from "../../util/date_time";
import React, {useContext} from "react";
import {TicketPageContext} from "../TicketPage";

export function TicketBeaconSessionsTable() {
    const query = useContext(TicketPageContext)!;

    const list = query.data!.ticket.beaconSessions;
    return <div>
        <Typography variant={"h6"}>
            Bluetooth beacon sessions
        </Typography>

        <table>
            <thead>
            <tr>
                <th>Major</th>
                <th>Minor</th>
                <th>Transport mode</th>
                <th>Vogn-id</th>
                <th>Tidsperiode</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {list.map(session => <tr>
                <td>{session.major}</td>
                <td>{session.minor}</td>
                <td>{session.transportMode}</td>
                <td>{session.vehicleId}</td>
                <td>{formatTimeRange(session.startTime, session.endTime)}</td>
                <td align={"right"}>{parseTime(session.endTime).diff(parseTime(session.startTime)).as("minutes").toFixed(0) + " min"}</td>
            </tr>)}
            </tbody>
        </table>
    </div>
}