import { CustomerPageQuery } from "../../generated/gql/graphql";
import React from "react";
import { Alert, Grid } from "@mui/material";
import { formatCustomerId } from "../../util/formatting";
import { TimestampText } from "../../widgets/date_time";
import { Property, PropertyList } from "../ticket_v2/TicketPageHeader";
import { JsonDiagnosticButton } from "../../widgets/diagnostics";

type CustomerPageHeaderProps = {
    customer: CustomerPageQuery["customer"];
};

export function CustomerPageHeader({ customer }: CustomerPageHeaderProps) {
    return (
        <div>
            <h2>
                Kunde: {formatCustomerId(customer.id)}
                <JsonDiagnosticButton name={"customer"} value={customer} />
            </h2>

            {customer.deletionRequestedTime ? (
                <Alert severity="error" style={{ marginBottom: "20px" }}>
                    Kunden deaktiverte kontoen sin <TimestampText value={customer.deletionRequestedTime} />, og denne
                    siden vil derfor ha begrensede opplysninger om brukeren.
                </Alert>
            ) : null}

            <Grid container spacing={2} direction={"row"}>
                <Grid item xs={6} md={6}>
                    <PropertyList>
                        <Property title={"Registrert"} value={<TimestampText value={customer.createdTime} />} />

                        <Property title={"Mobilnummer"} value={customer.phoneNumberFormatted} />
                        <Property
                            title={"HjemJobbHjem"}
                            value={customer.hasHJH ? "Ja" : customer.phoneNumber ? "Nei" : "-"}
                        />

                        <Property title={"Gruppe"} value={customer.researchGroup} />

                        <Property
                            title={"Telemetri-data"}
                            value={
                                customer.telemetryDataUploadConsentGiven
                                    ? "Bruker har samtykket til opplasting av telemetri-data"
                                    : "Mangler samtykke til opplasting av telemetri-data"
                            }
                        />
                    </PropertyList>
                </Grid>
                <Grid item xs={6} md={6}>
                    <PropertyList>
                        <Property title={"Rabatt"} value={customer.discount + "%"} />
                    </PropertyList>
                </Grid>
            </Grid>
        </div>
    );
}
