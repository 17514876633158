import React, {useEffect, useRef} from "react";
import {TicketEndedReason} from "../../../generated/gql/graphql";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {groupBy} from "lodash";
import {Filters, ISODate} from "../StatisticsProcessingPage";

type Row = [ISODate, TicketEndedReason | null, number];

export function TicketEndedReasonChart({filters}: { filters: Filters }) {
    const div = useRef(null);
    const data = useJsonData<Array<Row>>(buildUrl("/api/admin/statistics/ticket-ended-reason", filters));

    useEffect(() => {
        if (data != null) {
            const chart = new google.visualization.SteppedAreaChart(div.current!);

            const endedReasons: TicketEndedReason[] = Object.values(TicketEndedReason) as TicketEndedReason[];

            const dataContent: any[][] = [
                ["Date", ...endedReasons],
                ...groupByEndedReason(data, endedReasons),
            ];

            chart.draw(
                google.visualization.arrayToDataTable(dataContent),
                {
                    title: 'Ticket ended reason by ',
                    // curveType: 'function',
                    isStacked: "percent",
                    legend: {position: 'bottom'}
                }
            );
        }
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;
}

function groupByEndedReason(data: Array<Row>, endedReasons: TicketEndedReason[]): any[][] {
    const input: GroupInputRow<TicketEndedReason>[] = data.map((e) => [e[0], e[1] || TicketEndedReason.NotEnded, e[2]]);
    return groupByColumnWithCount(input, endedReasons);
}

export type GroupInputRow<GColumn> = [ISODate, GColumn, number]

export function groupByColumnWithCount<GColumn>(data: ReadonlyArray<GroupInputRow<GColumn>>, buckets: GColumn[]): any[][] {
    return Object.values(groupBy(data, 0))
        .map((dateRows: GroupInputRow<GColumn>[]) => {
            const date = dateRows[0][0];
            const row: any[] = [date];

            for (const bucketValue of buckets) {
                let dateColumnSum = 0;

                for (const row of dateRows) {
                    if (row[1] === bucketValue) {
                        dateColumnSum += row[2];
                    }
                }

                row.push(dateColumnSum);
            }

            return row;
        });
}
