import { VehicleTransportMode } from "../generated/gql/graphql";
import { gql } from "@apollo/client";

export const ACTIVE_TICKET_STATE_DETAILS_FRAGMENT = gql`
    fragment ActiveTicketStateDetails on ActiveTicketState {
        mode
        rawProtobufJson

        errorMessage
        stackTrace
        timestamp
    }
`;

export function formatVehiclePosition(
    vehicle?: {
        transportMode: VehicleTransportMode;
        tripId?: number | null;
        vehicleId: number;
        linePublicCode?: string | null;
        destination?: string | null;
    } | null,
): String {
    if (!vehicle) return "";
    return `${vehicle.transportMode} - ${vehicle.vehicleId}/${vehicle.linePublicCode || vehicle.tripId || null} ${
        vehicle.destination || ""
    }`;
}
