import React, {memo, useContext, useState} from "react";
import {TicketPageContext} from "../TicketPage";
import {gql} from "@apollo/client";
import {TicketLegFragment} from "../../generated/gql/graphql";
import {useMutation} from "@apollo/react-hooks";
import {formatDateFull, formatTime} from "../../util/date_time";
import {formatPercentage} from "../../util/formatting";
import {Icon, IconButton} from "@mui/material";
import {LegEditorModal} from "./LegEditorModal";

const TICKET_LEGS_FRAGMENT = gql`

    fragment TicketLeg on TicketLeg {
        id
        startedTime
        endedTime
        tripId
        vehicleId
        operatingDate

        analysisConfidence
        legZoneSpecDescription

        journey {
            id
            tripId
            serviceJourneyId

            line {
                id
                publicCode
            }
        }

        fromStopTimeIndex
        toStopTimeIndex

        fromPlatform{
            id
            name
            publicCode
            latitude
            longitude
        }
        toPlatform{
            id
            name
            publicCode
            latitude
            longitude
        }
    }

`;

export {TICKET_LEGS_FRAGMENT};
export function TicketLegsTable(props: {legs: ReadonlyArray<TicketLegFragment>}) {
    const list = props.legs;

    return <div>


        <table>
            <thead>
            <tr>
                <td>Dato</td>
                <td>Tidspunkt</td>
                <td>Fra holdeplass</td>
                <td>Til holdeplass</td>
                <td>Linje</td>
                <td>Trip id</td>
                <td>Vogn id</td>
                <td>%</td>
            </tr>
            </thead>
            <tbody>
            {list.map(leg => <TicketLegRow
                key={leg.id!}
                leg={leg}
            />)}
            </tbody>
        </table>

    </div>;
}

const DELETE_LEG_MUTATION = gql`
    mutation DeleteLeg ($legId: Int!){
        deleteTicketLeg(id: $legId)
    }
`;

const TicketLegRow = memo<{leg: TicketLegFragment}>(({leg}) => {
    const query = useContext(TicketPageContext)!;
    const mutation = useMutation(DELETE_LEG_MUTATION, {variables: {legId: leg.id}});
    const [modal, setModal] = useState<React.ReactNode>();

    return <tr key={leg.id!!}>
        <td>{formatDateFull(leg.startedTime)}</td>
        <td>
            <abbr title={leg.startedTime}>
                {formatTime(leg.startedTime)}
            </abbr>
            {" - "}
            <abbr title={leg.endedTime}>
                {formatTime(leg.endedTime)}
            </abbr>

        </td>
        <td>{leg.fromStopTimeIndex + " " + leg.fromPlatform?.name}</td>
        <td>{leg.toStopTimeIndex + " " + leg.toPlatform?.name}</td>
        <td>{leg.journey.line.publicCode}</td>
        <td>{leg.journey.tripId || leg.journey.serviceJourneyId}</td>
        <td>{leg.vehicleId}</td>
        <td>{formatPercentage(leg.analysisConfidence)}</td>
        <td>{leg.legZoneSpecDescription}</td>
        <td>
            <IconButton size={"small"} onClick={() => setModal(<LegEditorModal
                leg={leg}
                onClose={() => {
                    setModal(null);
                }}
                onSave={() => query.refetch().then(() => setModal(null))}
            />)}>
                <Icon>edit</Icon>
            </IconButton>
            <IconButton size={"small"} onClick={() => mutation[0]().then(() => query.refetch())}>
                <Icon>delete</Icon>
            </IconButton>
        </td>
        {modal}
    </tr>;
});