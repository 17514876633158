import { reverse, sortBy } from "lodash";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { TimestampText } from "../../widgets/date_time";
import React from "react";
import {
    CustomerPageQuery,
    CustomerSavedCardFragment,
    PaymentReconciliationFragment,
} from "../../generated/gql/graphql";
import gql from "graphql-tag";
import { Property, PropertyList } from "../ticket_v2/TicketPageHeader";
import { NETS_TRANSACTION_FRAGMENT, NetsTransactionsTable } from "../NetsTransactionsListPage";
import { JsonDiagnosticButton } from "../../widgets/diagnostics";
import { TraceButton } from "../../widgets/TraceButton";
import { isSuperUser } from "../../util/active_user_context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";

export const CUSTOMER_SAVED_CARDS_FRAGMENT = gql`
    fragment CustomerSavedCard on CustomerSavedCard {
        id
        issuer
        issuerId
        issuerCountry
        maskedPan
        netsTransactionId
        netsMerchantId
        netsMerchantEnvironment
        expirationDate
        cardTokenExpiredTime
        expired
        createdTime
        isActive
        transactions {
            ...NetsTransaction
        }
    }

    fragment PaymentReconciliation on PaymentReconciliation {
        id
        startedTime

        trace {
            traceId
            spanId
            sampled
        }
    }

    ${NETS_TRANSACTION_FRAGMENT}
`;

export function CustomerSavedCardsTab({ customer }: { customer: CustomerPageQuery["customer"] }) {
    const savedCards = sortBy(customer.savedCards as unknown as ReadonlyArray<CustomerSavedCardFragment>, "id");
    savedCards.reverse();

    return (
        <div>
            <Button href={"/api/admin/nets/reconcile?customerId=" + customer.id}>Reconcile payments</Button>
            {savedCards.map((savedCard) => (
                <SavedCardInfo key={savedCard.id} savedCard={savedCard} />
            ))}

            <PaymentReconciliationsTable
                paymentReconciliations={
                    customer.paymentReconciliations as unknown as ReadonlyArray<PaymentReconciliationFragment>
                }
            />
        </div>
    );
}

function SavedCardInfo({
    savedCard,
}: {
    savedCard: CustomerSavedCardFragment & CustomerPageQuery["customer"]["savedCards"][0];
}) {
    const transactions = sortBy(savedCard.transactions, "id").reverse();
    return (
        <Accordion style={{ marginBottom: "40px" }} defaultExpanded={savedCard.isActive}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={"h6"}>
                    Nets kort - {savedCard.isActive ? "Aktiv" : "Gammel"}{" "}
                    <JsonDiagnosticButton name={"savedCard"} value={savedCard} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {!savedCard.isActive ? (
                    <Alert color={"warning"}>
                        <AlertTitle>Deaktivert!</AlertTitle> Dette kortet er ikke lenger aktivt.
                    </Alert>
                ) : null}
                <PropertyList>
                    <Property requiresSuperUser title={"Id"} value={savedCard.id} />
                    <Property title={"Kortnummer"} value={savedCard.maskedPan} />
                    <Property title={"Utsteder"} value={savedCard.issuer} />
                    <Property title={"Utsteder-ID"} value={savedCard.issuerId} />
                    <Property title={"Utsteder land"} value={savedCard.issuerCountry} />
                    <Property title={"Utløpsdato"} value={savedCard.expirationDate} />
                    <Property requiresSuperUser title={"Nets merchant id"} value={savedCard.netsMerchantId} />

                    <Typography variant={"overline"} style={{ marginTop: "10px" }}>
                        Feltene nedenfor omhandler kort-registreringen.
                    </Typography>
                    <Property title={"Ordre-nummer"} value={savedCard.id} />
                    <Property title={"Nets transaksjons-id"} value={savedCard.netsTransactionId} />
                    <Property title={"Registrert"} value={<TimestampText value={savedCard.createdTime} />} />
                </PropertyList>
                <div style={{ width: "fit-content" }}>
                    <NetsTransactionsTable showCardInfo={false} transactions={reverse(sortBy(transactions, "id"))} />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function PaymentReconciliationsTable(props: { paymentReconciliations: ReadonlyArray<PaymentReconciliationFragment> }) {
    if (!isSuperUser()) return;
    const list = sortBy(props.paymentReconciliations, "startedTime").toReversed();

    return (
        <div style={{ width: "fit-content" }}>
            <Typography variant="h6">
                Payment reconciliations
                <DeveloperModeIcon fontSize={"small"} />
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Kjørt</TableCell>
                            <TableCell>Trace</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {list.map((r) => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>{r.id}</TableCell>
                                    <TableCell>
                                        <TimestampText value={r.startedTime} />
                                    </TableCell>
                                    <TableCell>
                                        <TraceButton trace={r.trace} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
