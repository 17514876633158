import React, { useEffect } from "react";

export default function LoginPage() {
    const isLoggedOut = window.location.href.indexOf("log-out-success") !== -1;

    if (isLoggedOut) {
        return (
            <div>
                <h1>Logged out</h1>
                <a href={"/oauth2/authorization/google"}>Logg inn</a>
            </div>
        );
    } else {
        return <LogInRedirect />;
    }
}

function LogInRedirect(): React.ReactElement {
    useEffect(() => {
        localStorage.setItem("LoginSuccess-RedirectTo", window.location.href);
        window.location.href = "/oauth2/authorization/google";
    }, []);

    return <div>Redirecting</div>;
}

export function useAuthorizedSuccessRedirect() {
    useEffect(() => {
        const redirectTo = localStorage.getItem("LoginSuccess-RedirectTo");
        console.log("redirectTo = ", redirectTo);
        localStorage.removeItem("LoginSuccess-RedirectTo");

        if (redirectTo) {
            window.location.href = redirectTo;
        }
    }, []);
}
