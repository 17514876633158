import {
    DeviceInfoFragment,
    DeviceLocationPermission,
    DevicePlatform,
    TicketPageV2Query,
} from "../../generated/gql/graphql";
import { Property, PropertyList } from "./TicketPageHeader";
import { Link as MaterialLink, Typography } from "@mui/material";
import { RelativeTimeText, TimestampText } from "../../widgets/date_time";
import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import gql from "graphql-tag";
import { DiagnosticButton, JsonDiagnosticButton, JsonDiagnosticProperty } from "../../widgets/diagnostics";
import { isSuperUser } from "../../util/active_user_context";
import { DateTimeFormat } from "../../util/date_time";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";

type TicketRow = TicketPageV2Query["ticket"];
type TicketAppTabProps = {
    ticket: TicketRow;
};

export function TicketAppTab({ ticket }: TicketAppTabProps) {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ flex: "1" }}>
                <CustomerWidget customer={ticket.customer} />
                <TimingWidget ticket={ticket} />
            </div>
            <div style={{ flex: "1" }}>
                <DeviceInfoWidget device={ticket.deviceInfo as DeviceInfoFragment} ticket={ticket} />
            </div>
        </div>
    );
}

function CustomerWidget({ customer }: { customer: TicketPageV2Query["ticket"]["customer"] }) {
    return (
        <PropertyList>
            <Typography variant={"h6"}>
                Kunde
                <JsonDiagnosticButton name={"ticket.customer"} value={customer} />
            </Typography>

            <Property title={"Registrert"} value={<TimestampText value={customer.createdTime} />} />

            <Property title={"Mobilnummer"} value={customer.phoneNumberFormatted} />
            <Property title={"HjemJobbHjem"} value={customer.hasHJH ? "Ja" : customer.phoneNumber ? "Nei" : "-"} />

            <Property title={"Gruppe"} value={customer.researchGroup} />
        </PropertyList>
    );
}

export const DEVICE_INFO_FRAGMENT = gql`
    fragment DeviceInfo on DeviceInfo {
        platform
        appBuildCode
        appVersionName

        androidManufacturer
        androidModel
        androidProduct
        androidVersionRelease
        androidVersionBaseOs
        androidVersionCodename
        androidVersionIncremental
        androidSdkInt

        iosModel
        iosSystemVersion

        sdkBuildTime
        sdkCommit
        sdkVersion

        locationPermission
        languageCode
        pinchEnabled
        clockDeviationMs

        firebaseAppName

        recordedTime
    }
`;

export function DeviceInfoWidget({
    device,
    ticket,
}: {
    device: DeviceInfoFragment | null | undefined;
    ticket?: TicketRow;
}) {
    if (!device) return <Typography>App versjon/Mobil metadata mangler.</Typography>;

    return (
        <PropertyList>
            <Typography variant={"h6"}>
                App-versjon
                <DiagnosticButton>
                    <JsonDiagnosticProperty name={"ticket.deviceInfo"} value={device} />
                </DiagnosticButton>
            </Typography>
            <Property title={"OS"} value={device.platform} />
            <Property title={"App-versjon"} value={device.appVersionName + "+" + device.appBuildCode} />
            <Property
                title={"Pinch/Kettle aktivert"}
                value={
                    device.pinchEnabled === null ? (
                        "Ukjent"
                    ) : device.pinchEnabled ? (
                        <div>
                            <b>Ja</b>
                            <WarningIcon color={"error"} style={{ marginLeft: "8px" }} />
                        </div>
                    ) : (
                        "Nei"
                    )
                }
            />
            <Property title={"Posisjons-tillatelse"} value={formatLocationPermission(device.locationPermission)} />

            <Property
                requiresSuperUser
                title={"clockDeviationMs"}
                value={formatClockDeviationMs(device.clockDeviationMs)}
            />

            {ticket ? <Property title={"Batteriforbruk"} value={formatBatteryUsage(ticket)} /> : null}

            {device.platform === DevicePlatform.Android ? (
                <div>
                    <Typography variant={"h6"} style={{ marginTop: "24px" }}>
                        Android info
                    </Typography>
                    <Property
                        title={"Modell"}
                        value={
                            <MaterialLink
                                href={
                                    "https://play.google.com/console/u/0/developers/6045451348399041272/app/4972996610679860688/devices?mode=supportedDevices&sortBy=INSTALLS&order=DESC&searchQuery=husky"
                                }
                                target={"_blank"}
                            >
                                {device.androidManufacturer + " – " + device.androidModel}
                            </MaterialLink>
                        }
                    />
                    <Property title={"Modell"} value={device.androidModel} />
                    <Property title={"Produktkode"} value={device.androidProduct} />
                    <Property
                        title={"OS versjon"}
                        value={
                            <span>
                                {device.androidVersionRelease} (SDK {device.androidSdkInt})
                            </span>
                        }
                    />
                </div>
            ) : null}

            {device.platform === DevicePlatform.Ios ? (
                <div>
                    <Typography variant={"h6"} style={{ marginTop: "24px" }}>
                        iOS info
                    </Typography>
                    <Property title={"Modell"} value={device.iosModel} />
                    <Property title={"OS versjon"} value={device.iosSystemVersion} />
                </div>
            ) : null}

            <Typography variant={"h6"} style={{ marginTop: "24px" }}>
                Reisefrihet SDK
            </Typography>
            <Property title={"Versjon"} value={device.sdkVersion} />
            <Property title={"Commit"} value={device.sdkCommit} />
        </PropertyList>
    );
}

export function formatLocationPermission(locationPermission: DeviceLocationPermission | null | undefined): string {
    switch (locationPermission) {
        case DeviceLocationPermission.Always:
            return "Alltid";
        case DeviceLocationPermission.WhileInUse:
            return "Når i bruk";
        case DeviceLocationPermission.Denied:
        case DeviceLocationPermission.DeniedForever:
            return "Avslått";
        default:
            return locationPermission || "";
    }
}

export function formatClockDeviationMs(clockDeviationMs: number) {
    if (clockDeviationMs == 0) return null;

    const text = Math.round(clockDeviationMs / 1000) + " sek";

    if (Math.abs(clockDeviationMs) >= 8000) {
        return (
            <span>
                {text} <WarningIcon />
            </span>
        );
    }

    return text;
}

export function formatBatteryUsage(ticket: { startBatteryLevel: number; endedBatteryLevel: number }) {
    if (ticket.startBatteryLevel == 0 || ticket.endedBatteryLevel == 0) return null;

    const usage = ticket.startBatteryLevel - ticket.endedBatteryLevel;
    return usage + " %";
}

function TimingWidget({ ticket }: TicketAppTabProps) {
    if (!isSuperUser()) return null;

    return (
        <div style={{ marginTop: "24px" }}>
            <Typography variant={"h6"}>
                Timing
                <DeveloperModeIcon fontSize={"small"} />
            </Typography>
            <Property
                vertical
                title={"created_time"}
                value={<TimestampText format={DateTimeFormat.TIME_SHORT} value={ticket.createdTime} />}
            />
            <Property
                vertical
                title={"purchase_request_phone_time"}
                value={
                    <RelativeTimeText
                        showTimeDifference
                        includeSeconds
                        value={ticket.purchaseRequestPhoneTime}
                        relativeTo={ticket.createdTime}
                    />
                }
            />
            <Property
                vertical
                title={"ended_time"}
                value={
                    <RelativeTimeText
                        showTimeDifference
                        includeSeconds
                        value={ticket.endedTime}
                        relativeTo={ticket.createdTime}
                    />
                }
            />
            <Property
                vertical
                title={"processed_time"}
                value={
                    <RelativeTimeText
                        showTimeDifference
                        includeSeconds
                        value={ticket.processedTime}
                        relativeTo={ticket.endedTime}
                    />
                }
            />
            <Property
                vertical
                title={"invoiced_time"}
                value={
                    <RelativeTimeText
                        showTimeDifference
                        includeSeconds
                        value={ticket.invoicedTime}
                        relativeTo={ticket.endedTime}
                    />
                }
            />
        </div>
    );
}
