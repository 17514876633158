import React, { HTMLAttributes, memo, Suspense } from "react";
import { styled, Tab, TabProps, Tabs, TabsTypeMap } from "@mui/material";
import NonScrollablePageStructure from "./NonScrollablePageStructure";
import LoadingScreen from "./LoadingScreen";

export default memo((props) => {
    return (
        <NonScrollablePageStructure>
            <div
                style={{
                    display: "flex",
                    alignItems: "stretch",
                    flexFlow: "column",
                    width: "100%",
                    height: "100%",
                }}
                {...props}
            />
        </NonScrollablePageStructure>
    );
});

const TabbedView_Content = memo<{ children: React.ReactNode }>(({ children, ...props }) => {
    return (
        <div
            style={{
                flex: "1",
                overflowY: "auto",
                position: "relative",
            }}
            {...props}
        >
            <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
        </div>
    );
});

// @ts-ignore
const TabbedView_Tabs = memo<TabsTypeMap["props"]>((props) => (
    <Tabs
        indicatorColor="primary"
        textColor="primary"
        centered
        style={{
            borderBottom: "1px solid #ddd",
            minHeight: "48px",
        }}
        {...props}
    />
));

const TabbedView_CenterAdjustedContent = memo<Partial<{ maxWidth: any | undefined }> & HTMLAttributes<HTMLDivElement>>(
    ({ maxWidth, style, ...props }) => {
        return (
            <div
                style={{
                    width: "100%",
                    margin: "0 auto",
                    padding: "30px 0",

                    maxWidth: maxWidth ? maxWidth + "px" : "810px",
                    ...style,
                }}
                {...props}
            />
        );
    },
);

const TabbedView_Tab = styled((props: TabProps & { to?: string }) => <Tab {...props} />)(() => ({
    root: {
        minWidth: "0 !important",
        minHeight: "48px",
        marginRight: "10px",
        padding: "4px 20px !important",
    },
    wrapper: {
        textTransform: "none",
        flexDirection: "row",
        fontSize: "15px",
    },
}));

export { TabbedView_Tab, TabbedView_Tabs, TabbedView_CenterAdjustedContent, TabbedView_Content };
