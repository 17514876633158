import { CustomerPageQuery, DeviceInfoFragment } from "../../generated/gql/graphql";
import { DeviceInfoWidget } from "../ticket_v2/TicketAppTab";
import React from "react";
import { Property } from "../ticket_v2/TicketPageHeader";
import { TimestampText } from "../../widgets/date_time";

type CustomerAppTabProps = {
    customer: CustomerPageQuery["customer"];
};

export function CustomerAppTab({ customer }: CustomerAppTabProps) {
    const device = customer.deviceInfo as DeviceInfoFragment | null;

    return (
        <div style={{ display: "flex" }}>
            <div style={{ flex: "1" }}>
                <DeviceInfoWidget device={device} />
                <Property title={"Oppdatert"} value={<TimestampText value={device?.recordedTime} />} />
            </div>
            <div style={{ flex: "1" }}>{/*<CustomerWidget customer={customer.customer} />*/}</div>
        </div>
    );
}
