import React, {useContext, useState} from "react";
import {TicketPageContext} from "../TicketPage";
import {DateTimeFormat, parseTime} from "../../util/date_time";
import {Link as MaterialLink} from "@mui/material";
import {RelativeTimeText} from "../../widgets/date_time";

export function TicketEventsListView() {
    const query = useContext(TicketPageContext)!;

    const ticket = query.data!.ticket;
    const list = ticket.ticketEvents;

    return <table width={"100%"}>
        <thead>
        <tr>
            <th style={{width: "230px"}}>Recorded time</th>
            <th>Type</th>
            <th>Json</th>
        </tr>
        </thead>
        <tbody>
        {list?.map((event) => {
            let delay: React.ReactNode = null;

            if (event.registeredTime) {
                const registeredTime = parseTime(event.registeredTime);
                const recordedTime = parseTime(event.recordedTime);

                const delaySeconds = Math.round(registeredTime.diff(recordedTime).as("seconds"));

                delay = <abbr
                    style={Math.abs(delaySeconds) > 3 ? {fontWeight: "bold"} : {}}
                    title={"RegisteredTime: " + registeredTime.toFormat(DateTimeFormat.TIME)}
                >
                    {" (" + delaySeconds + "s)"}
                </abbr>;
            }

            return <tr key={event.uuid}>
                <td style={{whiteSpace: "nowrap"}}>
                    <RelativeTimeText
                        includeSeconds
                        value={event.recordedTime}
                        relativeTo={ticket.createdTime}
                    />
                    {delay}
                </td>
                <td>{event.type}</td>
                <td>{event.providerName}</td>
                <td><RawProtobufExpandable json={event.jsonSerialized} /></td>
            </tr>;
        })}
        </tbody>
    </table>;
}

function RawProtobufExpandable(props: {json: string}): React.ReactElement {
    const [expanded, setExpanded] = useState(false);

    if (props.json) {
        return <MaterialLink onClick={() => setExpanded(!expanded)}>
            {expanded ? <pre>{props.json}</pre> : props.json}
        </MaterialLink>;
    } else {
        return <>null</>;
    }
}