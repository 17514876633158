import React from "react";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";

export default function LoadingModal() {
    return (
        <Dialog open>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
}
