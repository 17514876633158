import {gql} from "@apollo/client";
import React, {memo, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    Activity,
    AssetSnapshotQuery,
    AssetSnapshotQueryVariables,
    Ticket,
    TicketLegFragment,
    TicketRecordedPosition,
    TicketsPageQuery,
    VehiclePositionFragment
} from "../../generated/gql/graphql";
import {useQuery} from "@apollo/react-hooks";
import {difference, findIndex, sortBy} from "lodash";
import {calculateDistance} from "../../util/coordinates";
import {Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import TreeMap from "ts-treemap";
import {parseTime} from "../../util/date_time";
import {TicketPageContext} from "../TicketPage";
import {LegEditorModal} from "./LegEditorModal";
import {NewLegCatalogModal} from "./NewLegCatalogModal";
import {useLazyRef} from "@apollo/client/react/hooks/internal";
import {lightBlue} from "@mui/material/colors";
import LatLngBounds = google.maps.LatLngBounds;

const ASSET_SNAPSHOT_QUERY = gql`
    query AssetSnapshot($timestamp: String!) {
        assetSnapshot(timestamp: $timestamp) {
            assets{
                ...VehiclePosition
            }
        }
    }

    fragment VehiclePosition on VehiclePosition {
        tripId
        operatingDate
        vehicleId
        linePublicCode
        latitude
        longitude
    }
`;

function createBounds(positionLog: ReadonlyArray<TicketRecordedPosition>): LatLngBounds | null {
    let bounds: LatLngBounds | null = null;

    positionLog.forEach((position) => {
        const pos = toLatLng(position);

        if (bounds == null) {
            bounds = new LatLngBounds(pos);
        }

        if (!bounds.contains(pos)) {
            bounds = bounds.extend(pos);
        }
    });

    return bounds;
}

export const TicketDataView = memo(() => {
    const mapContainer = useRef<HTMLDivElement>(null);
    let mapRef = useRef<google.maps.Map | null>(null);
    const [modal, setModal] = useState<React.ReactNode>(null);
    let selectedRecordedPositionMarkerRef = useRef<google.maps.Marker>(new google.maps.Marker());
    const [selectedRecordedPosition, setSelectedRecordedPosition] = useState<TicketRecordedPosition | null>(null);
    const [filterProvider, setFilterProvider] = useState<string | null>(null);

    const {current: assetMarkers} = useRef<{[tripId: string]: google.maps.Marker}>({});
    const {current: legPolylines} = useRef<google.maps.Polyline[]>([]);

    const assetSnapshotQuery = useQuery<AssetSnapshotQuery, AssetSnapshotQueryVariables>(ASSET_SNAPSHOT_QUERY, {
        variables: {timestamp: selectedRecordedPosition?.recordedTime as string},
        skip: selectedRecordedPosition === null
    });

    const assets = useMemo<VehiclePositionFragment[]>(() => {
        let positions = (assetSnapshotQuery.data?.assetSnapshot?.assets || []) as VehiclePositionFragment[];
        return selectedRecordedPosition
            ? sortBy(positions, asset => calculateDistance(asset, selectedRecordedPosition!))
            : [];
        // eslint-disable-next-line
    }, [assetSnapshotQuery.data]);

    const query = useContext(TicketPageContext)!;
    const ticket = query.data!.ticket;
    const {createdTime, endedTime} = ticket;
    const legs = query.data!.ticket.legs as unknown as ReadonlyArray<TicketLegFragment> & Ticket["legs"];

    let positionLog: ReadonlyArray<TicketRecordedPosition> = sortBy(ticket.positionLog, "recordedTime");

    if (filterProvider) {
        positionLog = positionLog.filter((s) => s.androidLocationProvider === filterProvider);
    }

    useEffect(() => {

        let center = {lat: 58.9305979, lng: 5.6927593};

        if (positionLog.length > 0) {
            center = {
                lat: positionLog[0].latitude,
                lng: positionLog[1].longitude
            };
        }

        mapRef.current = new google.maps.Map(mapContainer.current!, {
            center,
            zoom: 11
        });

        const bounds = createBounds(positionLog);
        if (bounds)
            mapRef.current!.fitBounds(bounds, 20);
    }, []);

    const polylinePrePurchaseRef = useLazyRef<google.maps.Polyline>(() => new google.maps.Polyline({
        map: mapRef.current!,
        path: [],
        strokeColor: "#AAA"
    }));
    const polylineActiveRef = useLazyRef<google.maps.Polyline>(() => new google.maps.Polyline({
        map: mapRef.current!,
        path: []
    }));
    const polylinePostEndRef = useLazyRef<google.maps.Polyline>(() => new google.maps.Polyline({
        map: mapRef.current!,
        path: []
    }));

    useEffect(() => {
        const log = positionLog;
        const before = log.filter((position) => position.recordedTime < createdTime);
        const after = endedTime != null
            ? log.filter((position) => position.recordedTime > endedTime!)
            : [];
        const during = difference(log, before, after);

        polylinePrePurchaseRef.current!.setPath(before.map(toLatLng));
        polylineActiveRef.current!.setPath(during.map(toLatLng));
        polylinePostEndRef.current!.setPath(after.map(toLatLng));

        const map = mapRef.current!;
        polylinePrePurchaseRef.current!.setMap(map);
        polylineActiveRef.current!.setMap(map);
        polylinePostEndRef.current!.setMap(map);

    }, [filterProvider, positionLog.length]);

    useEffect(() => {
        const marker = selectedRecordedPositionMarkerRef.current;
        const map = mapRef.current!;

        if (selectedRecordedPosition !== null) {
            const position = {lat: selectedRecordedPosition.latitude, lng: selectedRecordedPosition.longitude};
            marker.setPosition(position);
            marker.setMap(map);

            map.panTo(position);
            if (map.getZoom() < 13) {
                map.setZoom(15);
            }
        } else {
            marker.setMap(null);
        }
    }, [selectedRecordedPosition]);

    useEffect(() => {
        const assets = (assetSnapshotQuery.data?.assetSnapshot?.assets || []) as VehiclePositionFragment[];
        const vehicleIds = assets.map(a => (a.vehicleId ?? a.tripId)!!);

        Object.keys(assetMarkers)
            .filter(vehicleId => !vehicleIds.includes(parseInt(vehicleId)))
            .forEach(vehicleId => {
                assetMarkers[vehicleId].setMap(null);
                delete assetMarkers[vehicleId];
            });

        assets.forEach((a) => {
            const markerId = (a.vehicleId || a.tripId)!!;
            let marker = assetMarkers[markerId];

            if (!marker) {
                const infowindow = new google.maps.InfoWindow({
                    content: a.linePublicCode + ` (${a.tripId}/${a.vehicleId})`
                });

                marker = new google.maps.Marker({
                    icon: {url: "/baseline_directions_bus_black_18dp.png"}
                });
                marker.addListener("click", function() {
                    infowindow.open(mapRef.current!, marker);
                });
                assetMarkers[markerId] = marker;
            }

            marker.setMap(mapRef.current);
            marker.setPosition({lat: a.latitude, lng: a.longitude});
            marker.setTitle(a.linePublicCode + " (" + a.tripId + ")");
        });

        // eslint-disable-next-line
    }, [assetSnapshotQuery]);

    useEffect(() => {

        legPolylines.splice(0).forEach((polyline) => polyline.setMap(null));


        for (let leg of legs) {
            const strokeColor = legColors[legs.indexOf(leg) % legColors.length];

            const before = leg.journey.positionLog.filter(({recordedTime}) => recordedTime < leg.startedTime);
            const during = leg.journey.positionLog.filter(({recordedTime}) => recordedTime >= leg.startedTime && recordedTime <= leg.endedTime);
            const after = leg.journey.positionLog.filter(({recordedTime}) => recordedTime > leg.endedTime);

            console.log(before, after, during);

            const polyline = new google.maps.Polyline({
                map: mapRef.current!,
                path: during.map(position => ({lat: position.latitude, lng: position.longitude})),
                strokeColor
            });

            const beforePolyline = new google.maps.Polyline({
                map: mapRef.current!,
                path: before.map(position => ({lat: position.latitude, lng: position.longitude})),
                strokeColor,
                strokeOpacity: 0.3
            });

            const afterPolyline = new google.maps.Polyline({
                map: mapRef.current!,
                path: after.map(position => ({lat: position.latitude, lng: position.longitude})),
                strokeColor,
                strokeOpacity: 0.3
            });

            legPolylines.push(polyline);
            legPolylines.push(beforePolyline);
            legPolylines.push(afterPolyline);
        }

        // eslint-disable-next-line
    }, [legs]);

    return <div style={{
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        height: "100%"
    }}>
        <div style={{
            width: "400px",
            overflowY: "auto"
        }}>

            <FormControlLabel
                control={<Checkbox checked={filterProvider === "GPS_PROVIDER"} onChange={(_, checked) => {
                    setFilterProvider(checked ? "GPS_PROVIDER" : null);
                }} />} label="Filter GPS_PROVIDER"
                disabled={ticket.positionLog.filter((p) => p.androidLocationProvider === "GPS_PROVIDER").length === 0}
            />

            <Log
                positions={positionLog}
                selectedRecordedPositionState={[selectedRecordedPosition, setSelectedRecordedPosition]}
            />
        </div>
        <div style={{

            flex: "1",
            display: "flex",
            flexFlow: "column",
            alignItems: "stretch"
        }}>
            <div ref={mapContainer} style={{flex: "1"}} />
            {selectedRecordedPosition ? <div style={{padding: "8px 24px"}}>
                    <Typography variant={"h6"}>
                        Opprett legg fra reisen:
                    </Typography>

                    <Button onClick={() => setModal(<NewLegCatalogModal
                        timestamp={selectedRecordedPosition?.recordedTime}
                        position={selectedRecordedPosition}
                        onClose={() => setModal(null)}
                        onSave={() => query.refetch().then(() => setModal(null))}
                    />)}>
                        Journey catalog
                    </Button>

                    {assets.slice(0, 20).map(asset => <Button variant={"outlined"} style={{marginRight: "20px"}}
                                                              disabled={asset.tripId === null}
                                                              onClick={() => setModal(<LegEditorModal
                                                                  leg={{
                                                                      tripId: asset.tripId!!,
                                                                      operatingDate: asset.operatingDate!,
                                                                      vehicleId: asset.vehicleId,
                                                                      fromStopTimeIndex: null,
                                                                      toStopTimeIndex: null
                                                                  }}
                                                                  onClose={() => setModal(null)}
                                                                  onSave={() => query.refetch().then(() => setModal(null))}
                                                              />)}>
                        {asset.linePublicCode + " (" + calculateDistance(asset, selectedRecordedPosition!).toFixed(0) + " m, " + asset.tripId + ", " + asset.vehicleId + ")"}
                    </Button>)}
                </div>
                : <Typography>
                    Velg posisjon fra loggen først
                </Typography>}
        </div>
        {modal}
    </div>;
});

type SelectedRecordedPositionState = [
        TicketRecordedPosition | null,
    (newValue: TicketRecordedPosition | null) => void
];

const legColors: string[] = [
    "#F7BC00",
    "#00AA50",
    lightBlue[500],
];

const Log = memo<{
    positions: ReadonlyArray<TicketRecordedPosition>,
    selectedRecordedPositionState: SelectedRecordedPositionState,
}>(({positions, selectedRecordedPositionState}) => {
    const query = useContext(TicketPageContext)!;
    const {ticket} = query.data!;


    const activityTreeMap = useMemo(() => {
        const treeMap = new TreeMap<String, Activity>();

        for (const activity of ticket.activityLog) {
            treeMap.set(activity.recordedTime, activity.activity);
        }

        return treeMap;
    }, [ticket.activityLog]);

    return <table style={{width: "100%"}} cellPadding={0} cellSpacing={0}>
        <thead>
        <tr>
            <td>Tidspunkt</td>
            <td>Aktivitet</td>
            <td>GPS-nøyaktighet</td>
            <td>Estimat</td>
        </tr>
        </thead>
        <tbody>
        {positions.map(position => {
            const activity = activityTreeMap.floorEntry(position.recordedTime);

            let legIndex: number | null = findIndex(query.data!.ticket.legs as unknown as ReadonlyArray<TicketLegFragment>, (leg: TicketLegFragment) => {
                return leg.startedTime <= position.recordedTime && position.recordedTime <= leg.endedTime;
            });
            if (legIndex === -1)
                legIndex = null;

            return <RecordedPosition
                key={position.uuid}
                ticket={ticket}
                position={position}
                legIndex={legIndex}
                selected={selectedRecordedPositionState[0] === position}
                onSelect={selectedRecordedPositionState[1]}
                activity={activity?.[1]}
            />;
        })}
        </tbody>
    </table>;
});

const RecordedPosition = memo<{
    selected: boolean,
    legIndex: number | null,
    onSelect: (position: TicketRecordedPosition) => void,
    ticket: TicketsPageQuery["ticket"],
    position: TicketRecordedPosition,
    activity: Activity | undefined
}>(({selected, legIndex, onSelect, ticket, position, activity}) => {
    const color = legIndex !== null ? legColors[legIndex % legColors.length] : undefined;
    const duringTicket = position.recordedTime >= ticket.createdTime && (!ticket.endedTime || position.recordedTime <= ticket.endedTime);

    return <tr
        key={position.uuid}
        onClick={() => onSelect(position)}
        style={{
            cursor: "pointer",
            background: selected ? "#DDD" : color,
            color: color !== undefined ? "white" : undefined,
            opacity: duringTicket ? undefined : "0.5"
        }}
    >
        <td>{parseTime(position.recordedTime).toFormat("HH:mm:ss")}</td>
        <td>{activity}</td>
        <td align={"right"}>
            {position.accuracy != null
                ? position.accuracy.toFixed(0) + " m"
                : null}
        </td>
        <td>
            {position.androidLocationProvider.replace("UNKNOWN_PROVIDER", "").replace("_PROVIDER", "")}
        </td>
    </tr>;
});


function toLatLng(position: TicketRecordedPosition): google.maps.LatLngLiteral {
    return {
        lat: position.latitude,
        lng: position.longitude
    };
}