import React, {useContext} from "react";
import {TicketPageContext} from "../TicketPage";
import {Link as MaterialLink, Typography} from "@mui/material";
import {TimestampText} from "../../widgets/date_time";

export function TicketAnalysisRevisionsTable() {
    const query = useContext(TicketPageContext)!;

    const list = query.data!.ticket.localAnalysisRevisions;
    return <div>
        <Typography variant={"h6"}>
            Analysis revisions
        </Typography>

        <table>
            <thead>
            <tr>
                <th>Checksum</th>
                <th>Uploaded time</th>
                <th>Analysis time</th>
                <th>Imported time</th>
            </tr>
            </thead>
            <tbody>
            {list.map((revision) => <tr key={revision.checksum}>
                <td>{revision.uploadedTime
                    ? <MaterialLink href={"/api/admin/ticket/analysis-revision.json?id=" + revision.checksum}>
                        {revision.checksum}
                    </MaterialLink>
                    : revision.checksum}</td>
                <td><TimestampText value={revision.uploadedTime}/></td>
                <td><TimestampText value={revision.analysisTime}/></td>
                <td><TimestampText value={revision.importedTime}/></td>
            </tr>)}
            </tbody>
        </table>
    </div>;

}