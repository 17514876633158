import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";
import React, { FormEvent, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { SendTicketReceiptEmailMutation, SendTicketReceiptEmailMutationVariables } from "../../generated/gql/graphql";
import gql from "graphql-tag";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type SendReceiptEmailDialogProps = {
    ticketId?: number;
    transactionId?: number;
    onClose: () => void;
    onSuccess: (email: string) => void;
};

const MUTATION = gql`
    mutation SendTicketReceiptEmail($transactionId: Int, $ticketId: Int, $email: String!) {
        sendReceiptEmail(transactionId: $transactionId, ticketId: $ticketId, email: $email) {
            id
        }
    }
`;

export function SendReceiptEmailDialog(props: SendReceiptEmailDialogProps) {
    const mutation = useMutation<SendTicketReceiptEmailMutation, SendTicketReceiptEmailMutationVariables>(MUTATION);
    const [email, setEmail] = useState<string>("");
    const sending = mutation[1].loading;
    const pdfUrl = props.ticketId
        ? "/api/admin/ticket/receipt?ticketId=" + props.ticketId
        : "/api/admin/transaction/receipt?transactionId=" + props.transactionId;

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (sending) return;

        mutation[0]({
            variables: {
                ticketId: props.ticketId,
                transactionId: props.transactionId,
                email,
            },
        }).then(() => {
            props.onSuccess(email);
        });
    }

    return (
        <Dialog open maxWidth={"lg"} onClose={props.onClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Send kvittering på e-post</DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        type={"email"}
                        label={"E-postadresse"}
                        variant={"filled"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ width: "300px", marginBottom: "24px" }}
                    />

                    <PdfPreview url={pdfUrl} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Avbryt</Button>
                    <Button type="submit" variant={"contained"}>
                        {sending ? (
                            <CircularProgress
                                style={{
                                    color: "#FFF",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "8px",
                                }}
                            />
                        ) : null}
                        Send
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

function PdfPreview({ url }: { url: string }) {
    return (
        <Accordion
            elevation={0}
            defaultExpanded={!!localStorage.getItem("sendReceiptExpanded")}
            onChange={(e, expanded) => {
                localStorage.setItem("sendReceiptExpanded", expanded ? "1" : "");
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
                <Typography variant={"h6"}>Forhåndsvisning</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <iframe width={1000} height={800} src={url} />
            </AccordionDetails>
        </Accordion>
    );
}
