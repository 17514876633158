import React, { useContext } from "react";

export type ActiveUser = {
    email: string;
    accessLevel: AccessLevel;
};

enum AccessLevel {
    DEVELOPER = "DEVELOPER",
    PROJECT = "PROJECT",
    SUPPORT = "SUPPORT",
}

let activeUser: ActiveUser | null | undefined = undefined;

export async function fetchActiveUser(): Promise<ActiveUser | null> {
    let response = await fetch("/api/auth/me", { redirect: "manual" });

    const isOk = response.status >= 200 && response.status < 300;
    if (!isOk) return null;

    const user = await response.json();
    return user as ActiveUser;
}

export function setActiveUser(user: ActiveUser | null) {
    activeUser = user;
}

export function getActiveUser(): ActiveUser | null | undefined {
    return activeUser;
}

export const ActiveUserContext = React.createContext<ActiveUser | null>(null);

export function isSuperUser(): boolean {
    // return false;
    return activeUser?.accessLevel === AccessLevel.DEVELOPER;
}

export function useIsSuperUser(): boolean {
    // return false;
    const me = useContext(ActiveUserContext);
    return me?.accessLevel === AccessLevel.DEVELOPER;
}
