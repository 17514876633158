import {gql} from "@apollo/client";
import React, {memo, useContext, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    NewLegTripJourneyDetailsQuery,
    NewLegTripJourneyDetailsQueryVariables,
    NewLegTripMutation,
    NewLegTripMutationVariables
} from "../../generated/gql/graphql";
import OutlinedSelectField, {OutlinedSelectFieldOptions} from "../../widgets/OutlinedSelectField";
import {sortBy} from "lodash";
import {calculateDistance} from "../../util/coordinates";
import {formatTime, parseTime, parseTimeOrNull} from "../../util/date_time";
import LoadingModal from "../../widgets/LoadingModal";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import FormGrid from "../../widgets/FormGrid";
import {TicketPageContext} from "../TicketPage";
import {TimeField} from "@mui/x-date-pickers";
import {DateTime} from "luxon";

const NEW_LEG_TRIP_QUERY = gql`
    query NewLegTripJourneyDetails(
        $journeyId: Int,
        $tripId: Int,
        $serviceJourneyId: String,
        $operatingDate: String!
    ){
        journey(journeyId: $journeyId, tripId: $tripId, serviceJourneyId: $serviceJourneyId, operatingDate: $operatingDate){
            id
            line{
                id
                publicCode
            }
            stopTimes{
                platform{
                    id
                    name
                    publicCode
                    latitude
                    longitude
                }
                boarding
                alighting
            }
            positionLog {
                latitude
                longitude
                recordedTime
            }
        }
    }
`;

const NEW_LEG_TRIP_Mutation = gql`
    mutation NewLegTrip(
        $id: Int,
        $ticketId: Int!
        $journeyId: Int!,
        $vehicleId: Int
        $fromStopTimeIndex: Int!
        $toStopTimeIndex: Int!,
        $startedTime: ISOTimestamp,
        $endedTime: ISOTimestamp,
    ){
        saveLeg(
            id: $id,
            ticketId: $ticketId,
            journeyId: $journeyId,
            vehicleId: $vehicleId,
            fromStopTimeIndex: $fromStopTimeIndex,
            toStopTimeIndex: $toStopTimeIndex,
            startedTime: $startedTime,
            endedTime: $endedTime,
        ){
            id
        }
    }
`;

export const LegEditorModal = memo<{
    leg: {
        id?: number | null,
        journey?: {id?: number},
        tripId?: number | null,
        serviceJourneyId?: string | null,
        operatingDate: string,
        vehicleId?: number | null | undefined,
        fromStopTimeIndex?: number | null,
        toStopTimeIndex?: number | null,
        startedTime?: string,
        endedTime?: string,
    }
    onClose: () => void,
    onSave: () => Promise<void>,
}>(({leg, onClose, onSave}) => {
    console.log(leg);
    const ticket = useContext(TicketPageContext)!.data!.ticket;
    const query = useQuery<NewLegTripJourneyDetailsQuery, NewLegTripJourneyDetailsQueryVariables>(NEW_LEG_TRIP_QUERY, {
        variables: {
            journeyId: leg.journey?.id || null,
            tripId: leg.tripId,
            serviceJourneyId: leg.serviceJourneyId,
            operatingDate: leg.operatingDate
        }
    });
    const mutation = useMutation<NewLegTripMutation, NewLegTripMutationVariables>(NEW_LEG_TRIP_Mutation);
    const [fromStopTimeIndex, setFromStopTimeIndex] = useState<number | null | undefined>(leg.fromStopTimeIndex);
    const [toStopTimeIndex, setToStopTimeIndex] = useState<number | null | undefined>(leg.toStopTimeIndex);
    const [vehicleId, setVehicleId] = useState<string>(leg.vehicleId ? leg.vehicleId.toString() : "");
    const [startedTime, setStartedTime] = useState<DateTime | null>(parseTimeOrNull(leg.startedTime));
    const [endedTime, setEndedTime] = useState<DateTime | null>(parseTimeOrNull(leg.endedTime));

    function useOptions(disabledFn: (stopTime: NewLegTripJourneyDetailsQuery["journey"]["stopTimes"][0]) => boolean) {
        return useMemo<OutlinedSelectFieldOptions[]>(() => {
            return query.data?.journey?.stopTimes?.map((stopTime, index) => {
                const closestRecordedPosition = sortBy(ticket.positionLog, position => calculateDistance(position, stopTime.platform))[0];
                const distance = calculateDistance(closestRecordedPosition, stopTime.platform);

                // const opacity = Math.sqrt(Math.max(0, 200 - distance) / 200);
                let color: string | undefined;
                if (distance < 2000) {
                    const opacity = distance < 2000 ? 1 - clamp(Math.log(distance) / 10.0, 0, 1) : 0;
                    color = "rgba(76, 175, 80, " + opacity + ")";
                }

                return {
                    value: index,
                    label: stopTime.platform.name,
                    primary: stopTime.platform.name + (stopTime.platform.publicCode ? " (" + stopTime.platform.publicCode + ")" : ""),
                    secondary: formatTime(closestRecordedPosition.recordedTime) + ", " + distance.toFixed(0) + " m",
                    disabled: disabledFn(stopTime),
                    backgroundColor: color
                };
            }) || [];
            // eslint-disable-next-line
        }, [query.data]);
    }

    const fromOptions = useOptions((stopTime) => !stopTime.boarding);
    const toOptions = useOptions((stopTime) => !stopTime.alighting);

    if (query.loading)
        return <LoadingModal />;

    return <Dialog open={true} onClose={onClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{leg.id ? "Rediger legg" : "Opprett legg"}</DialogTitle>
        <DialogContent>
            <FormGrid>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <OutlinedSelectField
                            required
                            id={"LegEditor-from"}
                            label={"Fra holdeplass"}
                            value={fromStopTimeIndex}
                            options={fromOptions!}
                            onChange={(v) => {
                                setFromStopTimeIndex(v);
                                setEndedTime(null);
                            }}
                        />
                    </Grid>
                    {startedTime ? <Grid item md={6}>
                        <TimeField
                            ampm={false}
                            label={"Start"}
                            format="HH:mm:ss"
                            referenceDate={parseTime(ticket.createdTime)}
                            value={startedTime}
                            onChange={(e) => setStartedTime(e as DateTime)}
                        />
                    </Grid> : null}
                </Grid>


                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <OutlinedSelectField
                            required
                            id={"LegEditor-to"}
                            label={"Til holdeplass"}
                            value={toStopTimeIndex}
                            options={toOptions!}
                            onChange={(v) => {
                                setToStopTimeIndex(v);
                                setEndedTime(null);
                            }}
                        />
                    </Grid>

                    {endedTime ? <Grid item md={6}>
                        <TimeField
                            ampm={false}
                            label={"End"}
                            format="HH:mm:ss"
                            referenceDate={parseTime(ticket.createdTime)}
                            value={endedTime}
                            onChange={(e) => setEndedTime(e as DateTime)}
                        />
                    </Grid> : null}
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <TextField
                            label={"Vogn id"}
                            fullWidth
                            value={vehicleId}
                            onChange={(e) => setVehicleId(e.target.value.trim())}
                        />
                    </Grid>
                </Grid>
            </FormGrid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Lukk
            </Button>
            <Button disabled={fromStopTimeIndex == null || toStopTimeIndex == null} onClick={async () => {
                console.log("startedTime", startedTime);
                await mutation[0]({
                    variables: {
                        id: leg.id,
                        journeyId: query.data!.journey.id,
                        vehicleId: vehicleId ? parseInt(vehicleId) : null,
                        ticketId: ticket.id,
                        fromStopTimeIndex: fromStopTimeIndex!,
                        toStopTimeIndex: toStopTimeIndex!,
                        startedTime: startedTime,
                        endedTime: endedTime,
                    }
                });
                await onSave();
            }}>
                Lagre
            </Button>
        </DialogActions>
    </Dialog>;
});

function clamp(num: number, min: number, max: number): number {
    return num <= min
        ? min
        : num >= max
            ? max
            : num;
}
