import {Dialog, DialogContent, DialogTitle, Link} from "@mui/material";
import React, {useContext, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {
    NewLegDepartureCandidatesQuery, NewLegDepartureCandidatesQueryVariables, TransportMode,
} from "../../generated/gql/graphql";
import {gql} from "@apollo/client";
import {TicketPageContext} from "../TicketPage";
import LoadingModal from "../../widgets/LoadingModal";
import {formatTime, parseTime} from "../../util/date_time";
import {DateTime} from "luxon";
import {sortBy} from "lodash";
import {calculateDistance} from "../../util/coordinates";
import {LegEditorModal} from "./LegEditorModal";


const NEW_LEG_DEPARTURE_CANDIDATES_QUERY = gql`
    query NewLegDepartureCandidates(
        $ticketId: Int!
        $timestamp: String!,
        $latitude: Float!,
        $longitude: Float!,
    ){
        ticketDepartureCandidates(
            ticketId: $ticketId,
            timestamp: $timestamp,
            latitude: $latitude,
            longitude: $longitude
        ) {
            stopTimeIndex
            scheduledDepartureTime
            platform {
                id
                latitude
                longitude
                name
            }
            journey {
                serviceJourneyId
                tripId
                operatingDate
                
                destination
                transportMode

                line {
                    publicCode
                }
            }
        }
    }
`;


export function NewLegCatalogModal(props: {
    timestamp: string,
    position: { latitude: number, longitude: number },
    onClose: () => void,
    onSave: () => Promise<void>,
}) {
    const {data} = useContext(TicketPageContext)!;
    const [modal, setModal] = useState<React.ReactNode>(null);
    const query = useQuery<NewLegDepartureCandidatesQuery, NewLegDepartureCandidatesQueryVariables>(NEW_LEG_DEPARTURE_CANDIDATES_QUERY, {
        variables: {
            ticketId: data!.ticket.id,
            timestamp: props.timestamp,
            latitude: props.position.latitude,
            longitude: props.position.longitude,
        }
    });
    if (query.loading)
        return <LoadingModal/>;

    const candidates = sortBy(
        query.data!.ticketDepartureCandidates,
        (stopTime) => {
            const journey = stopTime.journey;
            let transportModeSortVar: string = journey.transportMode;

            if (journey.transportMode == TransportMode.Rail) {
                transportModeSortVar = "0";
            }


            const distanceToPlatform = Math.round(calculateDistance(stopTime.platform, props.position) / 100);
            return transportModeSortVar + distanceToPlatform + stopTime.platform.name + stopTime.scheduledDepartureTime;
        }
    );

    return <Dialog open={true} onClose={props.onClose} maxWidth={"lg"}>
        <DialogTitle>{"Select departure"}</DialogTitle>
        <DialogContent>
            <table>
                <thead>
                <tr>
                    <th></th>
                    <th>Transport mode</th>
                    <th>Platform</th>
                    <th>Scheduled departure time</th>
                    <th>Departure delay</th>
                    <th>Line public code</th>
                    <th>Destination</th>
                </tr>
                </thead>
                <tbody>
                {candidates.map((stopTime) => {
                    const deviation = getTimeDeviationMinutes(props.timestamp, stopTime.scheduledDepartureTime);
                    const outsideTimeRange = deviation < -5 || deviation > 10;

                    return <tr style={{color: outsideTimeRange ? "#999" : "#000"}}>
                        <td>
                            <Link
                                onClick={() => setModal(<LegEditorModal
                                    leg={{
                                        tripId: stopTime.journey.tripId,
                                        serviceJourneyId: stopTime.journey.serviceJourneyId,
                                        operatingDate: stopTime.journey.operatingDate,
                                        vehicleId: null,
                                        fromStopTimeIndex: stopTime.stopTimeIndex,
                                    }}
                                    onClose={() => setModal(null)}
                                    onSave={() => props.onSave()}
                                />)}
                            >
                                New leg
                            </Link>
                        </td>
                        <td>{stopTime.journey.tripId}</td>
                        <td>{stopTime.journey.transportMode}</td>
                        <td>{stopTime.platform.name}</td>
                        <td>{formatTime(stopTime.scheduledDepartureTime)}</td>
                        <td>{deviation + " min"}</td>
                        <td>{stopTime.journey.line.publicCode}</td>
                        <td>{stopTime.journey.destination}</td>
                    </tr>
                        ;
                })}
                </tbody>
            </table>
            {modal}
        </DialogContent>
    </Dialog>;
}

function getTimeDeviationMinutes(legStartedTime: string, scheduleDepartureTime: string): number {
    const legStartedDateTime: DateTime = parseTime(legStartedTime)
    const scheduleDepartureDateTime: DateTime = parseTime(scheduleDepartureTime!)
    const deviation = scheduleDepartureDateTime.toSeconds() - legStartedDateTime.toSeconds()

    const minutes = Math.round(deviation / 60);
    return minutes;
}