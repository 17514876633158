import React, {useContext} from "react";
import {TicketPageContext} from "../TicketPage";
import {formatDateTimeMed} from "../../util/date_time";
import {Typography} from "@mui/material";
import {formatPercentage} from "../../util/formatting";
import {formatMoney} from "../NetsTransactionsListPage";
import {sumBy} from "lodash";


export function TicketAnalysisLocalPriceTable() {
    const query = useContext(TicketPageContext)!;

    const list = query.data!.ticket.analysisLocalPrice;

    return <div>
        <Typography variant={"h6"}>
            Ticket Local Price
        </Typography>

        <table>
            <thead>
            <th></th>
            <th>Created time</th>
            <th>%</th>
            <th>Legs</th>
            <th>Undiscounted price (discounted)</th>
            </thead>
            <tbody>
            {list.map((row) => {
                const basePrice = sumBy(row.legs, "undiscountedPriceIncludingVat");
                const price = sumBy(row.legs, "discountedPriceIncludingVat");
                const legString = row.legs.map((leg) => {
                    const legPrice = leg.discountedPriceIncludingVat;
                    const legBasePrice = leg.undiscountedPriceIncludingVat;
                    return "[" + formatMoney(legPrice) + " (" + formatMoney(legBasePrice) + "), " + leg.zoneSpecString + "]";
                }).join(", ");


                return <tr key={row.id} style={row.active ? {} : {color: "#AAA"}}>
                    <td>{row.active ? "ACTIVE" : "INACTIVE"}</td>
                    <td>{formatDateTimeMed(row.createdTime)}</td>
                    <td>{formatPercentage(row.analysisConfidence)}</td>
                    <td>{legString}</td>
                    <td>{formatMoney(price) + " (" + formatMoney(basePrice) + ")"}</td>
                </tr>
            })}
            </tbody>
        </table>
    </div>
}