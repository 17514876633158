import React, {useEffect, useRef} from "react";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {groupBy} from "lodash";
import {Filters, ISODate} from "../StatisticsProcessingPage";

type Row = [ISODate, number];

export function TicketAnalysisConfidenceChart({filters}: {filters: Filters}) {
    const div = useRef(null);
    const data = useJsonData<Array<Row>>(buildUrl("/api/admin/statistics/ticket-analysis-confidence", filters));

    useEffect(() => {
        if (data != null) {
            const chart = new google.visualization.SteppedAreaChart(div.current!);

            const dataBucketized = Object.values(groupBy(data, 0))
                .map((dateRows: Array<Row>) => {
                    const date= dateRows[0][0];
                    const buckets = [0, 0, 0, 0, 0, 0];

                    for(const row of dateRows) {
                        const confidence = row[1];

                        if(confidence >= 0.95){
                            buckets[0]++;
                        } else if(confidence >= 0.9) {
                            buckets[1]++;
                        } else if(confidence >= 0.8) {
                            buckets[2]++;
                        } else if(confidence >= 0.5) {
                            buckets[3]++;
                        } else if(confidence >= 0.1) {
                            buckets[4]++;
                        } else {
                            buckets[5]++;
                        }
                    }

                    return [date, ...buckets];
                });

            const dataContent: any[][] = [
                ["Date", "95%", "90%", "80%", "50%", "10%", "0%"],
                ...dataBucketized
            ];

            chart.draw(
                google.visualization.arrayToDataTable(dataContent),
                {
                    title: 'Ticket analysis confidence',
                    // curveType: 'function',
                    isStacked: "percent",
                    legend: {position: 'bottom'}
                }
            );
        }
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;
}