import {AppVersionName, Filters, ISODate, ISOTimestamp} from "../StatisticsProcessingPage";
import React, {useEffect, useRef} from "react";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {DevicePlatform, TicketEndedReason, TransportMode} from "../../../generated/gql/graphql";
import {groupByColumnWithCount, GroupInputRow} from "./TicketEndedReasonChart";
import {uniq} from "lodash";

type Row = [
    ticketCreatedTime: ISOTimestamp,
    platform: DevicePlatform | null,
    appVersion: AppVersionName | null,
    ticketEndedTime: ISOTimestamp | null,
    ticketEndedReason: TicketEndedReason,
    transportMode: TransportMode,
    legAnalysisConfidence: number,
    priceGuaranteeProduct: string
]

export function TicketLegTransportModeChart({filters}: { filters: Filters }) {

    const div = useRef(null);
    const data = useJsonData<Array<Row>>(buildUrl("/api/admin/statistics/ticket-leg-statistics", filters));

    useEffect(() => {
        if (data != null) {
            const chart = new google.visualization.SteppedAreaChart(div.current!);
            const transportModes: TransportMode[] = uniq(data.map((e) => e[5]));

            const dataContent: any[][] = [
                ["Date", ...transportModes],
                ...groupByTransportMode(data, transportModes)
            ];

            chart.draw(
                google.visualization.arrayToDataTable(dataContent),
                {
                    title: 'Transport mode',
                    // curveType: 'function',
                    isStacked: "percent",
                    legend: {position: 'bottom'},
                }
            );
        }
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;

}

function groupByTransportMode(data: Array<Row>, transportModes: TransportMode[]): any[][] {
    const input: GroupInputRow<TransportMode>[] = data.map((e) => [toIsoDate(e[0]), e[5], 1]);
    return groupByColumnWithCount(input, transportModes);
}

export function toIsoDate(timestamp: ISOTimestamp): ISODate {
    return timestamp.substring(0, 10);
}