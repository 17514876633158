// noinspection JSXDomNesting

import React, {memo, useContext, useState} from "react";
import {Link, Route, Routes, useLocation, useParams} from "react-router-dom";
import TabbedView, {
    TabbedView_CenterAdjustedContent,
    TabbedView_Content,
    TabbedView_Tab,
    TabbedView_Tabs
} from "../widgets/TabbedView";
import {QueryResult, useMutation, useQuery} from "@apollo/react-hooks";
import {
    CorrectLegsMutation,
    CorrectLegsMutationVariables,
    ProcessTicketMutation,
    ProcessTicketMutationVariables,
    TicketLegFragment,
    TicketPageHeaderFragment,
    TicketsPageQuery,
    TicketsPageQueryVariables
} from "../generated/gql/graphql";
import LoadingScreen from "../widgets/LoadingScreen";
import {Alert, Button, Icon, Typography} from "@mui/material";
import LoadingModal from "../widgets/LoadingModal";
import {green, red} from "@mui/material/colors";
import InvoicesView, {INVOICE_DETAILS_FRAGMENT} from "./ticket/InvoicesView";
import {gql} from "@apollo/client";
import {formatPercentage} from "../util/formatting";
import {TicketStatementHistoryTable} from "./ticket/TicketStatementHistoryTable";
import {TicketAnalysisLocalPriceTable} from "./ticket/TicketAnalysisLocalPriceTable";
import {TicketAnalysisRevisionsTable} from "./ticket/TicketAnalysisRevisionsTable";
import {TicketBeaconSessionsTable} from "./ticket/TicketBeaconSessionsTable";
import {TicketDataView} from "./ticket/TicketDataView";
import {TICKET_LEGS_FRAGMENT, TicketLegsTable} from "./ticket/TicketLegsTable";
import {TicketActiveTicketStateLogView} from "./ticket/TicketActiveTicketStateLogView";
import {ACTIVE_TICKET_STATE_DETAILS_FRAGMENT} from "../util/active_ticket_state";
import {TicketEventsListView} from "./ticket/TicketEventsListView";
import {TimestampText} from "../widgets/date_time";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {TICKET_PAGE_HEADER_FRAGMENT, TicketPageHeader} from "./ticket_v2/TicketPageHeader";
import {isSuperUser} from "../util/active_user_context";

const TicketPageContext = React.createContext<QueryResult<TicketsPageQuery, TicketsPageQueryVariables> | null>(null);

export {TicketPageContext};

const TICKETS_PAGE_QUERY = gql`
    query TicketsPage ($id: Int!){
        ticket(id: $id) {
            id
            verified

            ...TicketPageHeader
            customer{
                displayName
                phoneNumber
            }

            createdTime
            updatedTime
            endedTime
            processedTime
            analysisConfidence
            invoicedTime
            endedReason
            type
            passengerType

            price
            basePrice

            legs {
                ...TicketLeg
                journey {
                    positionLog {
                        latitude
                        longitude
                        recordedTime
                    }
                }
            }

            positionLog {
                uuid
                accuracy
                latitude
                longitude
                recordedTime
                androidLocationProvider
            }

            beaconSessions {
                major
                minor
                transportMode
                startTime
                endTime
                vehicleId
            }

            activityLog {
                recordedTime
                activity
            }

            activeTicketStateLog {
                ...ActiveTicketStateDetails
            }
            activeVehicleDetectedLog {
                recordedTime
                vehiclePosition {
                    vehicleId
                    linePublicCode
                    destination
                    transportMode
                    tripId
                }
                endConfidence
                confidence
                recognizedTransportMode
                rawProtobufJson
            }

            invoices {
                ...InvoiceWidgetDetails
            }

            localAnalysisRevisions {
                checksum
                uploadedTime
                analysisTime
                importedTime
                data {
                    result {
                        confidence
                        legs {
                            journey {
                                line {
                                    publicCode
                                }
                            }
                        }
                    }
                }
            }

            #            recognizedTransportModes {
            #                startTime
            #                endTime
            #                transportMode
            #            }

            analysisLocalPrice {
                id
                analysisConfidence
                createdTime
                legs {
                    id
                    analysisConfidence
                    discountedPriceIncludingVat
                    undiscountedPriceIncludingVat
                    zoneSpecString
                }
                active
            }

            statementHistory {
                id
                analysisResultType
                responseStatusCode
                responseStatusDescription
                ticketStatementJsonString
                ticketStatementReportResponseJsonString
                createdTime
                trace {
                    traceId
                    spanId
                    sampled
                }
            }

            ticketEvents {
                uuid
                type
                recordedTime
                registeredTime
                providerName
                jsonSerialized
            }
        }
    }

    ${INVOICE_DETAILS_FRAGMENT}
    ${ACTIVE_TICKET_STATE_DETAILS_FRAGMENT}
    ${TICKET_PAGE_HEADER_FRAGMENT}
    ${TICKET_LEGS_FRAGMENT}
`;

export default memo(() => {
    useLocation();
    const {ticketId} = useParams<{ticketId: string, page?: string}>();
    const query = useQuery<TicketsPageQuery, TicketsPageQueryVariables>(TICKETS_PAGE_QUERY, {variables: {id: parseInt(ticketId!)}});

    if (query.loading)
        return <LoadingScreen />;

    const baseUrl = "/ticket/" + ticketId;
    const page = window.location.hash.replace("#/ticket/" + ticketId + "/", "");
    const data = query.data!.ticket;

    return <TicketPageContext.Provider value={query}>
        <TabbedView>
            <Alert severity="error">
                Denne visningen er utdatert, og vil snart bli fjernet!
            </Alert>

            <TabbedView_Tabs value={page}>
                <TabbedView_Tab
                    value={"info"}
                    label={"Info"}
                    component={Link}
                    to={baseUrl + "/info"}
                />
                <TabbedView_Tab
                    value={"data"}
                    label={"Data"}
                    component={Link}
                    to={baseUrl + "/data"}
                    disabled={data.positionLog.length === 0}
                />
                {isSuperUser() ? <><TabbedView_Tab
                    value={"state-log"}
                    label={"State log"}
                    component={Link}
                    to={baseUrl + "/state-log"}
                    disabled={data.activeTicketStateLog.length === 0 && data.activeVehicleDetectedLog.length === 0}
                />
                    <TabbedView_Tab
                        value={"invoices"}
                        label={"Fakturaer"}
                        component={Link}
                        to={baseUrl + "/invoices"}
                    />
                    <TabbedView_Tab
                        value={"events"}
                        label={"Events (" + (data.ticketEvents ? data.ticketEvents.length : "null") + ")"}
                        component={Link}
                        to={baseUrl + "/events"}
                        disabled={!(data.ticketEvents && data.ticketEvents.length > 0)}
                    />
                </> : null}
                <TabbedView_Tab
                    value={"v2"}
                    iconPosition={"start"}
                    icon={<OpenInNewIcon />}
                    label={"v2 visning"}
                    component={Link}
                    to={"/ticket2/" + ticketId}
                    style={{marginLeft: "100px"}}
                />
            </TabbedView_Tabs>

            <TabbedView_Content>
                <Routes>
                    <Route path={"/data"} element={<TicketDataView />} />
                    <Route path={"/invoices"} element={<InvoicesView />} />
                    <Route path={"/state-log"} element={<TicketActiveTicketStateLogView />} />
                    <Route path={"/events"} element={<TicketEventsListView />} />
                    <Route path={"*"} element={<InfoView />} />
                </Routes>
            </TabbedView_Content>
        </TabbedView>
    </TicketPageContext.Provider>;
});

const CORRECT_LEGS_MUTATION = gql`
    mutation CorrectLegs($ticketId: Int!, $verified: Boolean!){
        updateTicket(ticketId: $ticketId, verified: $verified){
            id
        }
    }
`;

const PROCESS_TICKET_MUTATION = gql`
    mutation ProcessTicket($ticketId: Int!){
        processTicket(ticketId: $ticketId)
    }
`;

const TicketVerifiedActions = memo(() => {
    const query = useContext(TicketPageContext)!;
    const mutation = useMutation<CorrectLegsMutation, CorrectLegsMutationVariables>(CORRECT_LEGS_MUTATION);
    const [modal, setModal] = useState<React.ReactNode>(null);
    const {ticket} = query.data!;

    const handleSetCorrectLegs = (verified: boolean) => {
        setModal(<LoadingModal />);
        mutation[0](
            {
                variables: {
                    ticketId: query.data!.ticket.id,
                    verified
                }
            }
        ).then(() => query.refetch())
            .then(() => setModal(null));
    };

    const handleMarkCorrect = () => handleSetCorrectLegs(true);
    const handleMarkIncorrect = () => handleSetCorrectLegs(false);

    return ticket.verified
        ? <>
            <Button
                variant={"contained"}
                color={"primary"}
                disabled
                startIcon={<Icon>check</Icon>}
                style={{background: green[500], marginRight: "20px"}}
            >
                Reise riktig analysert
            </Button>
            <Button
                variant={"outlined"}
                onClick={handleMarkIncorrect}
                style={{color: red[500]}}
            >
                Marker reise ikke riktig analysert
            </Button>
            {modal}
        </>
        : <>
            <Button
                variant={ticket.verified ? "contained" : "outlined"}
                color={"primary"}
                disabled={ticket.verified}
                startIcon={<Icon>check</Icon>}
                onClick={handleMarkCorrect}
                style={ticket.verified ? {background: green[500]} : undefined}
            >
                Marker reise riktig analysert
            </Button>
            {modal}
        </>;

});
const InfoView = memo(() => {
    const query = useContext(TicketPageContext)!;
    const [modal, setModal] = useState<React.ReactNode>(null);
    const processMutation = useMutation<ProcessTicketMutation, ProcessTicketMutationVariables>(PROCESS_TICKET_MUTATION);

    const {ticket} = query.data!;

    const processTicket = () => {
        setModal(<LoadingModal />);

        processMutation[0]({variables: {ticketId: ticket.id}})
            .then(() => query.refetch())
            .then(() => setModal(null));
    };

    return <TabbedView_CenterAdjustedContent maxWidth={1200}>
        <TicketPageHeader ticket={ticket as unknown as TicketPageHeaderFragment} />

        <table>
            <tr>
                <td>UpdatedTime</td>
                <td><TimestampText value={ticket.updatedTime} /></td>
            </tr>
            <tr>
                <td>Processed time</td>
                <td><TimestampText value={ticket.processedTime} /></td>
            </tr>
            <tr>
                <td>Analysis confidence</td>
                <td>{formatPercentage(ticket.analysisConfidence)}</td>
            </tr>
            <tr>
                <td>Invoiced time</td>
                <td><TimestampText value={ticket.invoicedTime} /></td>
            </tr>
        </table>

        <TicketVerifiedActions />

        <Button
            startIcon={<Icon>refresh</Icon>}
            onClick={() => processTicket()}
            variant={"outlined"}
            color={"primary"}
            style={{marginLeft: "20px"}}
        >
            Behandle reise
        </Button>

        <Button
            startIcon={<Icon>data_object</Icon>}
            href={"/api/admin/ticket/replay.json?ticketId=" + ticket.id + "&redacted=false"}
            target={"_blank"}
            variant={"outlined"}
            color={"primary"}
            style={{marginLeft: "20px"}}
        >
            Replay.json
        </Button>

        <Button
            startIcon={<Icon>data_object</Icon>}
            href={"/api/admin/ticket/replay.pb?ticketId=" + ticket.id + "&redacted=false"}
            target={"_blank"}
            variant={"outlined"}
            color={"primary"}
            style={{marginLeft: "20px"}}
        >
            Replay.pb
        </Button>

        <Button
            startIcon={<Icon>data_object</Icon>}
            href={"/api/admin/ticket/replay.json?ticketId=" + ticket.id + "&redacted=true"}
            target={"_blank"}
            variant={"outlined"}
            color={"primary"}
            style={{marginLeft: "20px"}}
        >
            Replay.json (redacted)
        </Button>

        <Button
            startIcon={<Icon>data_object</Icon>}
            href={"/api/admin/ticket/replay.pb?ticketId=" + ticket.id + "&redacted=true"}
            target={"_blank"}
            variant={"outlined"}
            color={"primary"}
            style={{marginLeft: "20px"}}
        >
            Replay.pb (redacted)
        </Button>

        <Typography gutterBottom>
            Riktig analyserte reiser blir inkludert i treningen av maskinlærings-algoritmen.
        </Typography>

        <TicketLegsTable legs={ticket.legs as unknown as TicketLegFragment[]}/>
        <TicketAnalysisLocalPriceTable />
        <TicketAnalysisRevisionsTable />
        <TicketBeaconSessionsTable />
        <TicketStatementHistoryTable />
        {modal}
    </TabbedView_CenterAdjustedContent>;
});