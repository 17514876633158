import {Filters} from "../StatisticsProcessingPage";
import React, {useEffect, useRef} from "react";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {TicketStatisticRow} from "./TicketProcessPlatformChart";
import {sum} from "lodash";

type Row = TicketStatisticRow;


export function TicketPriceChart({filters}: { filters: Filters }) {

    const div = useRef(null);
    const data = useJsonData<Array<Row>>(buildUrl("/api/admin/statistics/ticket-statistics", filters));

    useEffect(() => {
        if (data != null) {
            const chart = new google.visualization.LineChart(div.current!);


            const dataContent: any[][] = [
                ["Time", "Price"],
                ...data.map((e) => ([e[0].substring(0, 10), e[5] / 100]))
            ];

            chart.draw(
                google.visualization.data.group(
                    google.visualization.arrayToDataTable(dataContent),
                    [0], [{
                        column: 1,
                        aggregation: sum,
                        label: "Avg price",
                        type: "number",
                    }]
                ),
                {
                    title: 'Ticket price (avg)',
                    legend: {position: 'bottom'},
                }
            );
        }
    }, [data]);

    return <div ref={div} style={{height: 500}}/>;

}