import { Tab } from "@mui/material";
import AdbIcon from "@mui/icons-material/Adb";
import React from "react";
import { ISOTimestamp } from "../views/statistics/StatisticsProcessingPage";
import { parseTime } from "../util/date_time";

export function CrashlyticsTab(props: {
    customerId: number;
    firebaseAppName: string | undefined;
    fromTime?: ISOTimestamp;
}) {
    const link = buildCrashlyticsLink(props);
    return (
        <Tab
            value={"crashlytics"}
            icon={<AdbIcon />}
            iconPosition={"start"}
            label={"Crashlytics"}
            component={"a"}
            href={link}
            disabled={!link}
            target={"_blank"}
        />
    );
}

export function buildCrashlyticsLink(props: {
    customerId: number;
    firebaseAppName: string | undefined;
    fromTime?: ISOTimestamp;
}): string {
    let time: string;

    if (props.fromTime) {
        const fromTime = parseTime(props.fromTime).minus({ hour: 2 });
        const toTime = fromTime.plus({ day: 7 });
        time = fromTime.toMillis() + ":" + toTime.toMillis();
    } else {
        time = "last-ninety-days";
    }

    return (
        "https://console.firebase.google.com/u/0/project/kolumbus-sanntid-app/crashlytics/app/" +
        props.firebaseAppName +
        "/search?time=" +
        time +
        "&q=" +
        props.customerId
    );
}
