import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
    DeviceInfoFragment,
    QuestionnaireFragment,
    TicketPageHeaderFragment,
    TicketPageV2Query,
    TicketPageV2QueryVariables,
    TicketTelemetryAvailability,
} from "../generated/gql/graphql";
import { Badge, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import React from "react";
import { TICKET_PAGE_HEADER_FRAGMENT, TicketPageHeader } from "./ticket_v2/TicketPageHeader";
import { Link, useParams } from "react-router-dom";
import { DEVICE_INFO_FRAGMENT, TicketAppTab } from "./ticket_v2/TicketAppTab";
import { TicketInfoTab } from "./ticket_v2/TicketInfoTab";
import { isSuperUser } from "../util/active_user_context";
import { usePageTitle } from "../util/page_title";
import MapIcon from "@mui/icons-material/Map";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ErrorIcon from "@mui/icons-material/Error";
import PollIcon from "@mui/icons-material/Poll";
import TicketComplaintsTab from "./ticket_v2/TicketComplaintsTab";
import { formatTicketId } from "../util/formatting";
import TicketQuestionnaireTab, { QUESTIONNAIRE_FRAGMENT } from "./ticket_v2/TicketQuestionnaireTab";
import BugReportIcon from "@mui/icons-material/BugReport";
import { TicketCrashlyticsTab } from "./ticket_v2/TicketCrashlyticsTab";

export const LOCAL_PRICE_FRAGMENT = gql`
    fragment LocalPrice on TicketAnalysisLocalPrice {
        id
        createdTime

        discountedPriceExcludingAddonsIncludingVat
        undiscountedPriceIncludingVat
        discountedPriceIncludingVat

        debugOrderSummaryJsonString
        debugTicketAnalysisResultJsonString
        analysisConfidence

        active
        legs {
            id
            analysisConfidence
            discountedPriceIncludingVat
            discountedPriceExcludingAddonsIncludingVat
            undiscountedPriceIncludingVat
            zoneSpecString
            startedTime

            priceGuaranteeProduct
            priceGuaranteeProductName
            billingTransportMode
            billingTransportModeName
            isTransfer
            distance

            debugInfo {
                endedTime
                vehicleId
                distance

                journey {
                    tripId
                    serviceJourneyId

                    destination
                    transportMode

                    line {
                        id
                        publicCode
                    }
                }
                toStopTime {
                    stopTimeIndex
                    platform {
                        id
                        name
                    }
                }
                fromStopTime {
                    stopTimeIndex
                    platform {
                        id
                        name
                    }
                }
            }
        }
    }
`;
const QUERY = gql`
    query TicketPageV2($id: Int!) {
        ticket(id: $id) {
            id

            createdTime
            price
            basePrice

            ...TicketPageHeader

            startBatteryLevel
            endedBatteryLevel

            telemetryAvailability
            purchaseRequestPhoneTime
            processedTime
            invoicedTime
            endedTime

            customer {
                id
                createdTime

                phoneNumber
                phoneNumberFormatted

                researchGroup
                hasHJH
            }

            deviceInfo {
                ...DeviceInfo
            }

            analysisLocalPrice {
                ...LocalPrice
            }

            complaints {
                id
                customerEmail
                description
                supportTicketUrl
                status
                statusChangedTime
                statusChangedByEmail
                originalPrice
                correctPrice
                internalComment
                customerMessage
                createdTime
                createdByEmail
                localPrice {
                    ...LocalPrice
                }
            }

            invoices {
                invoiceNumber
                descriptionNorwegian
                totalAmount
                createdTime

                transaction {
                    id
                    createdTime

                    amount
                    amountCaptured

                    invoices {
                        ticket {
                            id
                        }
                        totalAmount
                    }

                    savedCard {
                        id
                        maskedPan
                        issuer
                    }

                    netsCaptureParsedResponseCode
                    netsCaptureResponseCode
                    netsCaptureResponseText
                    netsCaptureResponseSource
                }

                transactionCredit {
                    id
                    amount
                    createdTime
                    processedTime
                    netsProcessParsedResponseCode
                    netsProcessResponseCode
                    netsProcessResponseText
                    netsProcessResponseSource
                    transaction {
                        id
                        savedCard {
                            id
                            maskedPan
                            issuer
                        }
                    }
                }
            }
            passengerTypeInfo {
                passengerTypeAddons
                addonBicycle
            }

            questionnaire {
                ...Questionnaire
            }
        }
    }

    ${TICKET_PAGE_HEADER_FRAGMENT}
    ${DEVICE_INFO_FRAGMENT}
    ${QUESTIONNAIRE_FRAGMENT}
    ${LOCAL_PRICE_FRAGMENT}
`;

export function TicketPageV2() {
    const { ticketId } = useParams<{ ticketId: string }>();
    const query = useQuery<TicketPageV2Query, TicketPageV2QueryVariables>(QUERY, {
        variables: { id: parseInt(ticketId!) },
    });
    const basePath = "/ticket2/" + ticketId;
    usePageTitle("Billett " + formatTicketId(ticketId));

    if (query.loading) return <CircularProgress />;

    const page = window.location.hash.replace("#/ticket2/" + ticketId, "").replace("/", "");
    const ticket = query.data!.ticket;
    const questionnaire = ticket.questionnaire as QuestionnaireFragment | undefined;

    function handleRefreshData(): Promise<unknown> {
        return query.refetch();
    }

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <div
                style={{
                    width: "100%",
                    padding: "20px",
                    maxWidth: "1400px",
                    margin: "0 auto",
                }}
            >
                <TicketPageHeader ticket={ticket as unknown as TicketPageHeaderFragment} />

                <Tabs value={page}>
                    <Tab value="" label={"Info"} component={Link} to={basePath} />
                    <Tab value="app" label={"App"} component={Link} to={basePath + "/app"} />
                    <Tab
                        value="complaints"
                        label={"Klager"}
                        component={Link}
                        to={basePath + "/complaints"}
                        icon={ticket.complaints.length > 0 ? <ErrorIcon color={"error"} /> : undefined}
                        iconPosition={"end"}
                    />
                    <Tab
                        value="questionnaire"
                        iconPosition={"start"}
                        icon={
                            questionnaire?.answered ? (
                                <Badge color="secondary" variant="dot">
                                    <PollIcon color={"success"} />
                                </Badge>
                            ) : (
                                <PollIcon />
                            )
                        }
                        label={"Spørreskjema"}
                        component={Link}
                        to={basePath + "/questionnaire"}
                        disabled={!ticket.questionnaire}
                    />
                    <Tab
                        value="telemetry"
                        iconPosition={"start"}
                        icon={<MapIcon />}
                        label={"Kart"}
                        component={Link}
                        to={"/ticket/" + ticketId + "/data"}
                        disabled={ticket.telemetryAvailability != TicketTelemetryAvailability.Available}
                        // TODO: WARNING when telemetry is unavilable
                    />
                    {isSuperUser()
                        ? [
                              <Tab
                                  value={"v1"}
                                  iconPosition={"start"}
                                  icon={<OpenInNewIcon />}
                                  label={"v1 visning"}
                                  component={Link}
                                  to={"/ticket/" + ticketId}
                                  style={{ marginLeft: "100px" }}
                              />,
                              <Tab
                                  value={"crashlytics"}
                                  iconPosition={"start"}
                                  icon={<BugReportIcon />}
                                  label={"Crashlytics"}
                                  component={Link}
                                  to={basePath + "/crashlytics"}
                              />,
                          ]
                        : null}
                </Tabs>

                <div style={{ border: "1px solid #DDD" }}>
                    <CustomTabPanel page={""} selectedPage={page || ""}>
                        <TicketInfoTab ticket={ticket} />
                    </CustomTabPanel>

                    <CustomTabPanel page={"app"} selectedPage={page || ""}>
                        <TicketAppTab ticket={ticket} />
                    </CustomTabPanel>

                    <CustomTabPanel page={"complaints"} selectedPage={page || ""}>
                        <TicketComplaintsTab ticket={ticket} onRefreshData={handleRefreshData} />
                    </CustomTabPanel>

                    <CustomTabPanel page={"questionnaire"} selectedPage={page || ""}>
                        <TicketQuestionnaireTab ticket={ticket} />
                    </CustomTabPanel>

                    <CustomTabPanel page={"crashlytics"} selectedPage={page || ""}>
                        <TicketCrashlyticsTab ticket={ticket} />
                    </CustomTabPanel>
                </div>
            </div>
        </div>
    );
}

export interface TabPanelProps {
    children?: React.ReactNode;
    page: string;
    selectedPage: string;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, page, selectedPage, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={page !== selectedPage}
            id={`simple-tabpanel-${page}`}
            aria-labelledby={`simple-tab-${page}`}
            {...other}
        >
            {page === selectedPage && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
