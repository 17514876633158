import React, {useState} from "react";
import ScrollablePageStructure from "../../widgets/ScrollablePageStructure";
import {useCharts} from "../../util/script_loader";
import LoadingScreen from "../../widgets/LoadingScreen";
import {DevicePlatform} from "../../generated/gql/graphql";
import {Checkbox, FormControl, InputLabel, Select} from "@mui/material";
import {TicketEndedReasonChart} from "./charts/TicketEndedReasonChart";
import {ActiveCustomerChart} from "./charts/ActiveCustomerChart";
import {TicketAnalysisConfidenceChart} from "./charts/TicketAnalysisConfidenceChart";
import {TicketDurationChart} from "./charts/TicketDurationChart";
import {TicketLegTransportModeChart} from "./charts/TicketLegTransportModeChart";
import {TicketProcessPlatformChart} from "./charts/TicketProcessPlatformChart";
import {TicketPriceChart} from "./charts/TicketPriceChart";

export type ISODate = string;
export type ISOTimestamp = string;
export type AppVersionName = string;
export type Filters = {
    appVersion?: AppVersionName
    platform?: DevicePlatform
};

const appVersions = [
    "4.24.0",
    "4.23.0",
    "4.22.3",
    "4.22.2",
    "4.22.2",
    "4.21.0",
    "4.20.0",
    "4.19.1",
    "4.18.1",
    "4.17.5",
    "4.17.0",
    "4.16.7",
    "4.16.6",
    "4.16.4",
]

export function StatisticsProcessingPage() {
    const [options, setOptions] = useState<Filters>({});
    const loaded = useCharts();

    if (!loaded)
        return <LoadingScreen/>;

    return <ScrollablePageStructure>
        <AppVersionSelect
            value={options.appVersion}
            onChange={(appVersion) => setOptions(({...options, appVersion}))}
        />
        <Checkbox
            checked={options.platform === DevicePlatform.Ios}
            onChange={(e) => {
                setOptions({
                    ...options,
                    platform: e.target.checked ? DevicePlatform.Ios : undefined
                });
            }}
        />
        IOS
        <Checkbox
            checked={options.platform === DevicePlatform.Android}
            onChange={(e) => {
                setOptions({
                    ...options,
                    platform: e.target.checked ? DevicePlatform.Android : undefined
                });
            }}
        />
        Android

        <ActiveCustomerChart filters={options}/>
        <TicketAnalysisConfidenceChart filters={options}/>
        <TicketEndedReasonChart filters={options}/>
        <TicketPriceChart filters={options}/>
        <TicketDurationChart filters={options}/>
        <TicketLegTransportModeChart filters={options}/>
        <TicketProcessPlatformChart filters={options}/>
    </ScrollablePageStructure>;
}

function AppVersionSelect({
                              value,
                              onChange
                          }: { value: string | undefined, onChange: (value: string | undefined) => void }) {
    return <FormControl style={{width: "200px"}}>
        <InputLabel htmlFor="version-native-simple">App version</InputLabel>
        <Select
            native
            value={value}
            onChange={(e) => onChange(e.target.value as string | undefined)}
            inputProps={{
                name: 'version',
                id: 'version-native-simple',
            }}
        >
            <option aria-label="None" value=""/>
            {appVersions.map((version) => <option key={version} value={version}>{version}</option>)}
        </Select>
    </FormControl>;
}