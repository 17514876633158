import React, {useEffect, useRef} from "react";
import ScrollablePageStructure from "../../widgets/ScrollablePageStructure";
import {useCharts, useJsonData} from "../../util/script_loader";
import LoadingScreen from "../../widgets/LoadingScreen";
import {NetsTransactionAmountChart} from "./charts/NetsTransactionAmountChart";
import {DevicePlatform, TicketEndedReason} from "../../generated/gql/graphql";
import {parseTime} from "../../util/date_time";


export function HealthPage() {
    const loaded = useCharts();
    const healthData = useJsonData<HealthData>("/api/admin/statistics/health");

    if (!loaded)
        return <LoadingScreen/>;

    return <ScrollablePageStructure>
        {healthData ? <>
            <HealthGuages data={healthData}/>
            <TicketPriceHistorgram data={healthData}/>
            <TicketDurationHistogramChart data={healthData}/>
            <TicketEndedReasonBarChart data={healthData}/>
            <TicketAnalysisConfidenceScatterChart data={healthData}/>
        </> : null}
        <NetsTransactionAmountChart/>
    </ScrollablePageStructure>;
}

type HealthData = {
    tickets: ReadonlyArray<{
        id: number
        createdTime: number
        duration: number
        price: number | null
        endedReason: TicketEndedReason | null
        analysisConfidence: number
        platform: DevicePlatform | null
        appVersionName: string | null
    }>
    ticketsUnprocessed: number
    ticketsPendingInvoicing: number
    ticketCount: number
    ticketProcessedCount: number
    ticketInvoicedCount: number
    ticketEndedCount: number
    // ticketEndedReasonCounts: [[reason: TicketEndedReason]: number]
    invoiceCount: number
    invoiceTotalPositiveAmount: number
    invoiceTotalNegativeAmount: number
    analysisConfidenceAverage: number
}


function HealthGuages({data}: { data: HealthData }) {
    const div = useRef(null);

    useEffect(() => {
        const dataTable = google.visualization.arrayToDataTable([
            ['Label', 'Value'],
            ['Processed', data.ticketProcessedCount / data.ticketCount * 100],
            ['Invoiced', data.ticketInvoicedCount / data.ticketCount * 100],
            ['Ended', data.ticketEndedCount / data.ticketCount * 100],
            ["Issued/credit", (data.invoiceTotalPositiveAmount) / (data.invoiceTotalPositiveAmount - data.invoiceTotalNegativeAmount) * 100],
        ]);

        const chart = new google.visualization.Gauge(div.current!);

        chart.draw(dataTable, {
            width: 560, height: 240,
            redFrom: 0,
            redTo: 80,
            yellowFrom: 80,
            yellowTo: 90,
            greenFrom: 90,
            greenTo: 90,
            minorTicks: 5,
        });
    }, [data]);

    return <div ref={div}/>;
}

function TicketDurationHistogramChart({data}: { data: HealthData }) {
    const div = useRef(null);

    useEffect(() => {
        const dataTable = google.visualization.arrayToDataTable([
            ['Ticket', 'Duration (min)'],
            ...data.tickets
                .filter((e) => e.duration != null)
                .map((e) => (["Ticket (" + e.id + ")", (e.duration / 60)]))
        ]);

        const chart = new google.visualization.Histogram(div.current!);

        chart.draw(dataTable, {
            title: "Ticket duration (minutes)",
            height: 240,
            histogram: {
                bucketSize: 5,
            }
        });
    }, [data]);

    return <div ref={div} style={{width: "100%"}}/>;
}

function TicketPriceHistorgram({data}: { data: HealthData }) {
    const div = useRef(null);

    useEffect(() => {
        const dataTable = google.visualization.arrayToDataTable([
            ['Ticket', 'Price (kr)'],
            ...data.tickets
                .map((e) => (["Ticket (" + e.id + ")", ((e.price ?? 0) / 100)]))
        ]);

        const chart = new google.visualization.Histogram(div.current!);

        chart.draw(dataTable, {
            title: "Ticket price (kr)",
            height: 240,
            histogram: {
                bucketSize: 3,
            }
        });
    }, [data]);

    return <div ref={div} style={{width: "100%"}}/>;
}

function TicketEndedReasonBarChart({data}: { data: HealthData }) {
    const div = useRef(null);

    useEffect(() => {
        const dataTable = google.visualization.arrayToDataTable([
            ['Ended Reason'],
            ...data.tickets
                .map((e) => ([e.endedReason || TicketEndedReason.NotEnded]))
        ]);

        const chart = new google.visualization.BarChart(div.current!);

        chart.draw(google.visualization.data.group(dataTable, [0], [{
            label: "Count",
            type: "number",
            column: 0,
            aggregation: (e) => e.length,
        }]), {
            title: "Ticket price (kr)",
            width: 400,
            height: 240,
        });
    }, [data]);

    return <div ref={div} style={{width: "100%"}}/>;
}

function TicketAnalysisConfidenceScatterChart({data}: { data: HealthData }) {
    const div = useRef(null);

    useEffect(() => {
        const dataTable = new google.visualization.DataTable();
        dataTable.addColumn("date", "Created time");
        dataTable.addColumn("number", "iOS");
        dataTable.addColumn("number", "Android");
        dataTable.addColumn({type: 'string', role: 'tooltip'});

        for (const ticket of data.tickets) {
            if (ticket.platform)
                dataTable.addRow([
                    parseTime(ticket.createdTime).toJSDate(),
                    ticket.platform === DevicePlatform.Ios ? ticket.analysisConfidence * 100 : null,
                    ticket.platform === DevicePlatform.Android ? ticket.analysisConfidence * 100 : null,
                    "ticketId=" + ticket.id + ", " + ticket.platform + ", " + ticket.appVersionName
                ]);
        }

        const chart = new google.visualization.ScatterChart(div.current!);

        chart.draw(dataTable, {
            title: "Ticket analysis confidence (%)",
            width: 400,
            height: 300,
            series: {
                0: {axis: 'iOS'},
                1: {axis: 'Android'}
            },
        });
    }, [data]);

    return <div ref={div} style={{width: "100%"}}/>;
}