import { LocalPriceFragment, TicketPageV2Query, TicketRowFragment } from "../../generated/gql/graphql";
import React from "react";
import {
    Link as MaterialLink,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { RelativeTimeText, TimestampText } from "../../widgets/date_time";
import { formatMoney } from "../NetsTransactionsListPage";
import { sortBy, sumBy } from "lodash";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { Link } from "react-router-dom";
import { buildFlagIcon } from "../FrontPage";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { DiagnosticButton, JsonDiagnosticProperty } from "../../widgets/diagnostics";
import { Property, PropertyList } from "./TicketPageHeader";
import { formatPercentage } from "../../util/formatting";
import { isSuperUser } from "../../util/active_user_context";

type Ticket = TicketPageV2Query["ticket"];
type Invoice = Ticket["invoices"][0];
type Transaction = Invoice["transaction"] & {};
type TransactionCredit = Invoice["transactionCredit"] & {};

type TicketInfoTabProps = {
    ticket: Ticket;
};

function formatDistanceKm(distance: number): String {
    return (distance / 1000).toFixed(1) + " km";
}

export function TicketInfoTab({ ticket }: TicketInfoTabProps) {
    return (
        <div>
            {ticket.analysisLocalPrice.map((l) => {
                const localPrice = l as LocalPriceFragment;

                return <TicketLocalPriceSection key={localPrice.id} ticket={ticket} localPrice={localPrice} />;
            })}
            <InvoiceTransactionLog ticket={ticket} />
        </div>
    );
}

export function TicketLocalPriceSection({ ticket, localPrice }: { ticket: Ticket; localPrice: LocalPriceFragment }) {
    return (
        <div key={localPrice.id} style={{ marginBottom: "30px" }}>
            <div style={{ display: "flex" }}>
                <Typography variant={"h6"} style={localPrice.active ? {} : { textDecoration: "line-through" }}>
                    Billett-pris <RelativeTimeText value={localPrice.createdTime} relativeTo={ticket.createdTime} />
                    {localPrice.active ? null : (
                        <Tooltip title={"Denne billettprisen er ikke aktiv"}>
                            <DeleteIcon color={"error"} />
                        </Tooltip>
                    )}
                    <DiagnosticButton>
                        <JsonDiagnosticProperty name={"ticket.analysisLocalPrice[]"} value={localPrice} />
                        <JsonDiagnosticProperty
                            name={"localPrice.debugOrderSummaryJsonString"}
                            json={localPrice.debugOrderSummaryJsonString}
                        />
                        <JsonDiagnosticProperty
                            name={"localPrice.debugTicketAnalysisResultJsonString"}
                            json={localPrice.debugTicketAnalysisResultJsonString}
                        />
                    </DiagnosticButton>
                </Typography>
                <div style={{ flex: "1" }} />
            </div>
            <PropertyList>
                <Property
                    requiresSuperUser
                    title={"AnalysisConfidence"}
                    value={formatPercentage(localPrice.analysisConfidence)}
                />
            </PropertyList>
            <TicketLocalLegsTable ticket={ticket} localPrice={localPrice} />
        </div>
    );
}

export function TicketLocalLegsTable({
    ticket,
    localPrice,
}: {
    ticket: Ticket | TicketRowFragment;
    localPrice: LocalPriceFragment;
}) {
    const showPriceExcludingAddons =
        ticket.passengerTypeInfo.addonBicycle > 0 || ticket.passengerTypeInfo.passengerTypeAddons.length > 0;
    const legs = sortBy(localPrice.legs, "startedTime");

    if (legs.length == 0) return <Typography>Ingen reise-legg detektert.</Typography>;

    return (
        <Table style={{ width: "100%", marginBottom: "20px" }}>
            <TableHead>
                <TableRow>
                    <TableCell>Ombord</TableCell>
                    <TableCell>Transportmiddel</TableCell>
                    <TableCell>Linje</TableCell>
                    <TableCell>Destinasjon</TableCell>
                    <TableCell>Fra holdeplass</TableCell>
                    <TableCell>Til holdeplass</TableCell>
                    <TableCell>Trip-Id</TableCell>
                    <TableCell>Vogn-nr</TableCell>
                    <TableCell align={"right"}>Reiselengde</TableCell>
                    <TableCell>Sone</TableCell>

                    {showPriceExcludingAddons ? (
                        <TableCell align={"right"}>Rabattert pris (eksl. medpassasjerer/sykler)</TableCell>
                    ) : null}
                    <TableCell colSpan={2} align={"right"}>
                        Pris
                    </TableCell>
                    <TableCell>Prisgaranti</TableCell>
                    {isSuperUser() ? <TableCell>AnalysisConfidence</TableCell> : null}
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {legs.map((leg) => {
                    return (
                        <TableRow key={leg.id}>
                            <TableCell>
                                <RelativeTimeText
                                    includeSeconds={false}
                                    value={leg.startedTime}
                                    relativeTo={ticket.createdTime}
                                />
                                {leg.debugInfo?.endedTime ? (
                                    <>
                                        {" - "}
                                        <RelativeTimeText
                                            includeSeconds={false}
                                            value={leg.debugInfo?.endedTime}
                                            relativeTo={ticket.createdTime}
                                        />
                                    </>
                                ) : null}
                            </TableCell>
                            <TableCell>{leg.billingTransportModeName || "Krever 4.25+"}</TableCell>
                            <TableCell>{leg.debugInfo?.journey?.line?.publicCode}</TableCell>
                            <TableCell>{leg.debugInfo?.journey?.destination}</TableCell>
                            <TableCell>{leg.debugInfo?.fromStopTime?.platform?.name}</TableCell>
                            <TableCell>{leg.debugInfo?.toStopTime?.platform?.name}</TableCell>
                            <TableCell>
                                {leg.debugInfo?.journey?.tripId || leg.debugInfo?.journey?.serviceJourneyId}
                            </TableCell>
                            <TableCell>{leg.debugInfo?.vehicleId}</TableCell>
                            <TableCell align={"right"}>
                                {leg.distance > 0 ? formatDistanceKm(leg.distance) : "Krever 4.25+"}
                            </TableCell>
                            <TableCell>{leg.zoneSpecString}</TableCell>
                            {showPriceExcludingAddons ? (
                                <TableCell align={"right"}>
                                    {formatMoney(leg.discountedPriceExcludingAddonsIncludingVat)}
                                </TableCell>
                            ) : null}
                            <TableCell
                                align={"right"}
                                style={{
                                    textDecoration: "line-through",
                                    textAlign: "right",
                                }}
                            >
                                {formatMoney(leg.undiscountedPriceIncludingVat)}
                            </TableCell>
                            <TableCell align={"right"} style={{ textAlign: "right" }}>
                                {formatMoney(leg.discountedPriceIncludingVat)}
                            </TableCell>
                            <TableCell>
                                {leg.billingTransportMode ? leg.priceGuaranteeProductName : "Krever 4.25+"}
                            </TableCell>
                            {isSuperUser() ? <TableCell>{formatPercentage(leg.analysisConfidence)}</TableCell> : null}
                            <TableCell>
                                {buildFlagIcon(
                                    leg.isTransfer,
                                    <TransferWithinAStationIcon />,
                                    "Reisen har overgangsprising",
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
                <TableRow>
                    <TableCell colSpan={10}>Sum:</TableCell>
                    {showPriceExcludingAddons ? (
                        <TableCell align={"right"}>
                            {formatMoney(localPrice.discountedPriceExcludingAddonsIncludingVat)}
                        </TableCell>
                    ) : null}
                    <TableCell align={"right"} style={{ textDecoration: "line-through" }}>
                        {formatMoney(localPrice.undiscountedPriceIncludingVat)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        {formatMoney(localPrice.discountedPriceIncludingVat)}
                    </TableCell>
                    <TableCell colSpan={2} />
                </TableRow>
            </TableBody>
        </Table>
    );
}

function InvoiceTransactionLog({ ticket }: { ticket: Ticket }) {
    const children: Array<React.ReactNode> = [];

    let totalBalance = 0;
    let totalPaid = 0;

    const transactionIds = new Set<any>();

    const invoicesReversed = [...ticket.invoices];
    invoicesReversed.reverse();
    invoicesReversed.forEach((invoice) => {
        const transaction = invoice.transaction;

        if (transaction) {
            if (!transactionIds.has(transaction.id)) {
                children.push(<TransactionRow key={transaction.id} ticket={ticket} transaction={transaction} />);

                transactionIds.add(transaction.id);
                totalPaid += Math.min(transaction.amountCaptured, invoice.totalAmount);
            }
        }

        const transactionCredit = invoice.transactionCredit;
        if (transactionCredit) {
            const key = "TC-" + transactionCredit.id;
            if (!transactionIds.has(key)) {
                children.push(<TransactionCreditRow key={transactionCredit.id} credit={transactionCredit} />);

                transactionIds.add(key);
                totalPaid -= transactionCredit.amount;
            }
        }

        children.push(<InvoiceRow key={invoice.invoiceNumber} invoice={invoice} />);
        totalBalance += invoice.totalAmount;
    });

    children.reverse();

    return (
        <div>
            <Typography variant={"h6"}>Betalingshistorikk</Typography>
            <Table style={{ maxWidth: "1000px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Tid</TableCell>
                        <TableCell>Beskrivelse</TableCell>
                        <TableCell>Nets respons</TableCell>
                        <TableCell style={{ width: "140px" }}></TableCell>
                        <TableCell align={"right"} style={{ width: "100px" }}>
                            Billettpris
                        </TableCell>
                        <TableCell align={"right"} style={{ width: "100px" }}>
                            Betalt
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
                <TableFooter>
                    <SumRow totalInvoiceAmount={totalBalance} totalPaid={totalPaid} />
                </TableFooter>
            </Table>
        </div>
    );
}

function InvoiceRow({ invoice }: { invoice: Invoice }) {
    return (
        <TableRow>
            <TableCell>
                <TimestampText value={invoice.createdTime} />
            </TableCell>
            <TableCell>{invoice.descriptionNorwegian}</TableCell>
            <TableCell colSpan={2}></TableCell>
            <TableCell align={"right"}>{formatMoney(invoice.totalAmount)}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
}

function TransactionRow({ ticket, transaction }: { ticket: Ticket; transaction: Transaction }) {
    const relevantInvoices = transaction.invoices.filter((i) => {
        return i.ticket.id == ticket.id;
    });
    const ticketSum = sumBy(relevantInvoices, "totalAmount");

    let priceText = formatMoney(transaction.amount);
    if (ticketSum < transaction.amount) priceText += ", hvorav " + formatMoney(ticketSum) + " gjelder denne billetten";

    return (
        <TableRow key={"t+" + transaction.id}>
            <TableCell>
                <TimestampText value={transaction.createdTime} />
            </TableCell>
            <TableCell>
                <MaterialLink component={Link} to={"/nets-transaction/" + transaction.id}>
                    Kortbetaling ({priceText})
                </MaterialLink>
                <br />
                {formatCard(transaction.savedCard)}
            </TableCell>
            <TableCell colSpan={2}>
                <TransactionNetsResponseText transaction={transaction!} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell align={"right"}>{formatMoney(Math.min(transaction.amountCaptured, ticketSum))}</TableCell>
        </TableRow>
    );
}

function TransactionCreditRow({ credit }: { credit: TransactionCredit }) {
    return (
        <TableRow>
            <TableCell>
                <TimestampText value={credit.createdTime} />
            </TableCell>
            <TableCell>
                <MaterialLink component={Link} to={"/nets-transaction/" + credit.transaction.id}>
                    Kort refusjon
                </MaterialLink>
                <br />
                {formatCard(credit.transaction.savedCard)}
            </TableCell>
            <TableCell colSpan={2}>
                <TransactionNetsResponseText transaction={credit!} />
            </TableCell>
            <TableCell />
            <TableCell align={"right"}>-{formatMoney(Math.min(credit.amount))}</TableCell>
        </TableRow>
    );
}

export function TransactionNetsResponseText({
    transaction,
}: {
    transaction: {
        /// NetsTransaction
        readonly netsCaptureParsedResponseCode?: string | undefined | null;
        readonly netsCaptureResponseText?: string | undefined | null;
        readonly netsCaptureResponseCode?: string | undefined | null;
        readonly netsCaptureResponseSource?: string | undefined | null;
    } & {
        /// NetsTransactionCredit
        readonly netsProcessParsedResponseCode?: string | undefined | null;
        readonly netsProcessResponseText?: string | undefined | null;
        readonly netsProcessResponseCode?: string | undefined | null;
        readonly netsProcessResponseSource?: string | undefined | null;
    };
}) {
    const parsed = transaction.netsCaptureParsedResponseCode || transaction.netsProcessParsedResponseCode;
    const responseText = transaction.netsCaptureResponseText || transaction.netsProcessResponseText;
    const responseCode = transaction.netsCaptureResponseCode || transaction.netsProcessResponseCode;
    const responseSource = transaction.netsCaptureResponseSource || transaction.netsProcessResponseSource;

    let text = responseCode;

    if (responseSource) text += " / " + responseSource;

    return (
        <Typography>
            {responseCode == "OK" ? <CheckCircleIcon color={"success"} /> : <DangerousIcon color={"error"} />}

            <abbr
                title={parsed + "\nresponseText=" + responseText || ""}
                style={
                    responseCode == "OK"
                        ? {}
                        : {
                              color: "#F00",
                              fontWeight: "bold",
                          }
                }
            >
                {text}
            </abbr>
        </Typography>
    );
}

function formatCard(savedCard: { readonly issuer: string; readonly maskedPan: string }): string {
    return savedCard.issuer + " " + savedCard.maskedPan.substring(savedCard.maskedPan.length - 4);
}

function SumRow(props: { totalInvoiceAmount: number; totalPaid: number }) {
    const amountOwed = props.totalInvoiceAmount - props.totalPaid;

    function buildRow(text: string, col1: string, col2: string, style?: React.CSSProperties) {
        const mergedStyle: React.CSSProperties = { fontSize: "20px", color: "black", fontWeight: "400", ...style };

        return (
            <TableRow>
                <TableCell colSpan={3} />
                <TableCell style={mergedStyle}>{text}</TableCell>
                <TableCell align={"right"} style={mergedStyle}>
                    {col1}
                </TableCell>
                <TableCell align={"right"} style={mergedStyle}>
                    {col2}
                </TableCell>
            </TableRow>
        );
    }

    return [
        <TableRow>
            <TableCell colSpan={6} />
        </TableRow>,
        buildRow("Sum", formatMoney(props.totalInvoiceAmount), formatMoney(props.totalPaid), { fontWeight: "normal" }),
        <TableRow>
            <TableCell colSpan={6} />
        </TableRow>,
        amountOwed >= 0
            ? buildRow("Å betale", "", formatMoney(amountOwed))
            : buildRow("Å refundere", "", formatMoney(-amountOwed)),
    ];
}
