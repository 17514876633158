export function formatPercentage(float: number | null | undefined): string | null {
    if (float === null || float === undefined) return null;

    return (float * 100).toFixed(1) + "%";
}

export function formatTicketId(ticketId: string | number | null | undefined): string {
    if (!ticketId) return "";

    const string = ticketId.toString();
    if (string.length > 6) {
        return string.substring(0, 2) + "-" + string.substring(2, 6) + "-" + string.substring(6);
    } else if (string.length > 2) {
        return string.substring(0, 2) + "-" + string.substring(2);
    } else {
        return string;
    }
}

export function formatCustomerId(customerId: string | number | null | undefined): string {
    if (!customerId) return "";
    const string = customerId.toString();
    if (string.length <= 4) return string;

    return string.substring(0, 4) + "-" + string.substring(4);
}
