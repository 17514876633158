import { Button } from "@mui/material";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import React from "react";
import { isSuperUser } from "../util/active_user_context";

type TraceProps =
    | {
          traceId: string;
          spanId?: string | null;
          sampled: boolean;
      }
    | undefined
    | null;

export function TraceButton({ trace }: { trace: TraceProps }) {
    if (!isSuperUser()) return;
    const traceUrl = buildTraceUrl(trace);
    // const logUrl = buildLogUrl(trace);

    return (
        <Button
            disabled={!trace?.traceId || !trace.sampled}
            href={traceUrl || undefined}
            component={"a"}
            target={"_blank"}
            startIcon={<FormatIndentIncreaseIcon />}
            color={"primary"}
            variant={"contained"}
        >
            Trace
        </Button>
    );
    // {/*<IconButton href={logUrl || undefined} disabled={!logUrl} target={"_blank"} component={"a"}>*/}
    //        {/*    <ManageSearchIcon />*/}
    //        {/*</IconButton>*/}
}

function buildTraceUrl(trace: TraceProps): string | null {
    if (!trace || !trace.traceId) return null;

    return (
        "https://console.cloud.google.com/traces/list?project=kolumbus-sanntid-app&tid=" +
        trace.traceId +
        "&spanId=" +
        trace.spanId
    );
}

// function buildLogUrl(trace: TraceProps): string | null {
//     if (!trace || !trace.traceId) return null;
//
//     const template =
//         "https://console.cloud.google.com/logs/query;query=%2528labels.%22appengine.googleapis.com%2Ftrace_id%22%3D%22668996b8dee7eb58c3c5827302845269%22%20OR%20trace%3D%22projects%2Fkolumbus-sanntid-app%2Ftraces%2F668996b8dee7eb58c3c5827302845269%22%2529%20spanId%3D%22c3c5827302845269%22?project=kolumbus-sanntid-app";
//
//     return template
//         .replaceAll("668996b8dee7eb58c3c5827302845269", trace.traceId)
//         .replaceAll("c3c5827302845269", trace.spanId ?? "");
// }
