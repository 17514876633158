export type LatLng = [number, number] | { latitude: number | undefined; longitude: number | undefined };

export function calculateDistance(from: LatLng, to: LatLng) {
    const fromLat = Array.isArray(from) ? from[0] : from.latitude!;
    const fromLng = Array.isArray(from) ? from[1] : from.longitude!;

    const toLat = Array.isArray(to) ? to[0] : to.latitude!;
    const toLng = Array.isArray(to) ? to[1] : to.longitude!;

    const p = 0.017453292519943295;
    const c = Math.cos;
    const d = 0.5 - c((toLat - fromLat) * p) / 2 + (c(fromLat * p) * c(toLat * p) * (1 - c((toLng - fromLng) * p))) / 2;
    return 12742 * Math.asin(Math.sqrt(d)) * 1000;
}
