import React, {useEffect, useRef} from "react";
import {buildUrl, useJsonData} from "../../../util/script_loader";
import {Filters, ISODate} from "../StatisticsProcessingPage";

export function ActiveCustomerChart({filters}: {filters: Filters}) {
    const div = useRef(null);
    const data = useJsonData<Array<[ISODate, number]>>(buildUrl("/api/admin/statistics/active-customers", filters));

    useEffect(() => {
        if (data != null) {
            const chart = new google.visualization.LineChart(div.current!);

            chart.draw(
                google.visualization.arrayToDataTable([
                    ["Date", "Customers"],
                    ...data,
                ]),
                {
                    title: 'Active customers',
                    curveType: 'function',
                    legend: {position: 'bottom'}
                }
            );
        }
    }, [data]);

    return <div ref={div}/>;
}

