import { DateTimeFormat, parseTime } from "../util/date_time";

export function TimestampText(props: {
    format?: DateTimeFormat;
    value: string | null | undefined;
    includeSeconds?: boolean;
}) {
    if (props.value == null) {
        return null;
    } else {
        const format = props.format || DateTimeFormat.DATETIME_MED;
        const dateTime = parseTime(props.value);

        const localTime = dateTime.toFormat(format);

        const hoverFormat = DateTimeFormat.DATETIME_FULL;
        const osloTime = dateTime.setZone("Europe/Oslo").toFormat(hoverFormat);
        const utcTime = dateTime.setZone("UTC").toFormat(hoverFormat);
        const miamiTime = dateTime.setZone("America/New_York").toFormat(hoverFormat);

        return <abbr title={"Oslo: " + osloTime + "\nMiami: " + miamiTime + "\nUTC:" + utcTime}>{localTime}</abbr>;
    }
}

function getFormat(includeDate?: boolean, includeSeconds?: boolean) {
    return includeSeconds === false
        ? includeDate
            ? DateTimeFormat.DATETIME_MED
            : DateTimeFormat.TIME_SHORT
        : includeDate
          ? DateTimeFormat.DATETIME_LONG
          : DateTimeFormat.TIME;
}

export function RelativeTimeText(props: {
    showTimeDifference?: boolean;
    includeDate?: boolean;
    value: string | null | undefined;
    relativeTo: string | null | undefined;
    includeSeconds?: boolean;
}) {
    if (props.value == null) return null;
    if (!props.relativeTo) {
        return <TimestampText format={getFormat(props.includeDate, props.includeSeconds)} value={props.value} />;
    }

    const value = parseTime(props.value);
    const relativeTo = parseTime(props.relativeTo);

    const showDate = props.includeDate || value.toISODate() !== relativeTo.toISODate();

    const format = getFormat(showDate, props.includeSeconds);
    let child = <TimestampText value={props.value} format={format} />;

    if (props.showTimeDifference) {
        let offset = value.diff(relativeTo);

        const format = Math.abs(offset.as("minutes")) >= 60 ? "hh:mm:ss" : "mm:ss";
        const delaySeconds = offset.rescale().toFormat(format);

        return (
            <span>
                {child}
                <abbr
                    style={Math.abs(offset.as("seconds")) > 3 ? { fontWeight: "bold" } : {}}
                    title={"Relative to: " + relativeTo.toFormat(DateTimeFormat.TIME)}
                >
                    {offset.as("seconds") < 0 ? " -" : " +"}
                    {delaySeconds}
                </abbr>
            </span>
        );
    } else {
        return child;
    }
}
