import React, { memo, ReactNode } from "react";

export default memo<
    React.HTMLAttributes<HTMLDivElement> & {
        columns?: number;
        children: ReactNode[];
    }
>(({ columns, ...props }) => {
    return (
        <div
            style={{
                display: "grid",
                gridGap: "20px",
                gridTemplateColumns: columns ? "repeat(" + columns + ", 1fr)" : undefined,
            }}
            {...props}
        />
    );
});
