import React, {useEffect, useRef, useState} from "react";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";

const OutlinedSelectField = React.memo(({
                                            nullText,
                                            showErrors,
                                            id,
                                            required,
                                            multiple,
                                            label,
                                            value,
                                            onChange,
                                            options,
                                            autoFocus,
                                            helperText,
                                            error,
                                            name,
                                            ...otherProps
                                        }) => {
    const inputLabelRef = useRef();
    const labelWidthState = useState(0);

    useEffect(() => {
        labelWidthState[1](inputLabelRef.current.offsetWidth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const flattenedOptions = {};
    options.forEach(v => flattenedOptions[v.value] = v);

    if (typeof error === "undefined") {
        error = showErrors && required && !value;
    }

    return <FormControl fullWidth required={required} variant={"outlined"} error={error} {...otherProps}>
        <InputLabel ref={inputLabelRef} htmlFor={id} id={id ? id + "-label" : undefined}>{label}</InputLabel>
        <Select
            multiple={multiple}
            value={multiple ? (value || []) : (value === null || value === undefined ? "" : value)}
            onChange={event => {
                console.log(event);
                onChange(event.target.value, flattenedOptions[event.target.value]);
            }}
            input={<OutlinedInput labelWidth={labelWidthState[0]} />}
            labelId={id ? id + "-label" : undefined}
            id={id}
            name={name}
            renderValue={v => {
                if (multiple) {
                    return v.map(v => {
                        const value = flattenedOptions[v];
                        return value?.label || value?.primary;
                    }).join(", ");
                } else {
                    const value = flattenedOptions[v];
                    return value?.label || value?.primary;
                }
            }}
            autoFocus={autoFocus}

        >
            {!multiple && !required
                ? <MenuItem value={null}>
                    {nullText && <i>{nullText}</i>}
                </MenuItem>
                : null}

            {options.map(o => (
                <MenuItem key={o.value} value={o.value} data-testid-label={o.label} disabled={o.disabled}
                          style={{backgroundColor: o.backgroundColor}}
                          label={o.label}
                          aria-label={o.label}
                >
                    {multiple
                        ? <Checkbox checked={!!(value && value.includes(o.value))} style={{
                            padding: "0",
                            marginRight: "15px"
                        }} />
                        : null}
                    <ListItemText
                        primary={o.primary || o.label}
                        secondary={o.secondary}
                    />
                </MenuItem>
            ))}
        </Select>

        {helperText
            ? <FormHelperText>
                {helperText}
            </FormHelperText>
            : null}
    </FormControl>;
});

export default OutlinedSelectField;
;
