import {groupBy} from "lodash";


export function bucketize(data: Array<[string, number]>, buckets: number[]): any[][] {

    return Object.values(groupBy(data, 0))
        .map((dateRows: Array<[string, number]>) => {
            const date = dateRows[0][0];
            const dateBuckets: number[] = [...buckets.map(() => 0), 0];

            for (const row of dateRows) {
                const value = row[1];
                let foundBucket = false;

                for (const bucketIndex in buckets) {
                    const bucket = buckets[bucketIndex];
                    if (value <= bucket) {
                        dateBuckets[bucketIndex]++;
                        foundBucket = true;
                        break;
                    }
                }

                if (!foundBucket) {
                    dateBuckets[dateBuckets.length - 1]++;
                }
            }

            return [date, ...dateBuckets];
        });
}